import { useLocation } from "react-router-dom";
import { Message } from "../../interfaces/Chat";
import { useAuthStore } from "../../stores/authStore";
import { useChatStore } from "../../stores/chatStore";
import { socket } from "../../utils/socket";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

export default function Events() {
  const [_, setIsConnected] = useState(socket.connected);
  const authData = useAuthStore((state) => state.user);
  const setNewMessages = useChatStore((state) => state.setNewMessages);
  const setHasUnreadMessages = useChatStore(
    (state) => state.setHasUnreadMessages
  );
  const location = useLocation();

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      // console.log("connected");
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onFooEvent() {}

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("message", (message: Message[]) => {
      // console.log("message event", message);
      if (location.pathname.includes("/chat")) setNewMessages(message);
      else {
        toast.info("Tienes mensajes sin leer!");
        setHasUnreadMessages(true);
      }
    });

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("message", onFooEvent);
      socket.off("register", onFooEvent);
    };
  }, []);

  useEffect(() => {
    if (authData?.id) socket.emit("register", authData?.id);
  }, [authData]);

  return <div className="hidden"></div>;
}
