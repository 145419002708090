import { Card, Chip, Skeleton } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import PostCard from "../post/Post";
import { ActivityPost, Post } from "../../interfaces/Post";
import { useRef } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "../../assets/icons/ArrowIcon";

export default function ProfilePosts({
  isLoadingPosts,
  posts,
}: {
  isLoadingPosts: boolean;
  posts: ActivityPost[] | Post[];
}) {
  const containerPostsRef = useRef(null);
  const handleScrollLeftPosts = () => {
    if (containerPostsRef.current) {
      (containerPostsRef.current as HTMLDivElement).scrollLeft -= 360;
    }
  };
  const handleScrollRightPosts = () => {
    if (containerPostsRef.current) {
      (containerPostsRef.current as HTMLDivElement).scrollLeft += 360;
    }
  };
  return (
    <div className="mt-2 flex flex-col items-center w-full">
      <div
        ref={containerPostsRef}
        className="flex scroll-smooth justify-start w-[calc(100vw-10px)] 2xl:w-[90rem] overflow-auto hide-scroll"
      >
        <div
          className={`mt-4 py-3 flex gap-5 min-w-[calc(${
            isLoadingPosts ? 4 : posts.length
          }*330px)] sm:min-w-[calc(${
            isLoadingPosts ? 4 : posts.length
          }*360px)] md:min-w-[calc(${
            isLoadingPosts ? 4 : posts.length
          }*460px)] px-2 md:px-6`}
        >
          {isLoadingPosts
            ? [0, 0, 0, 0].map((_, i) => (
                <Card
                  key={i}
                  className="w-[330px] sm:w-[360px] md:w-[460px] h-[350px] space-y-5 p-4 rounded-2xl border-1 border-gray-200 shadow-none"
                >
                  <div className="max-w-[300px] w-full flex items-center gap-3">
                    <div>
                      <Skeleton className="flex rounded-full w-12 h-12" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <Skeleton className="h-3 w-3/5 rounded-lg" />
                      <Skeleton className="h-3 w-4/5 rounded-lg" />
                    </div>
                  </div>
                  <Skeleton className="rounded-lg">
                    <div className="h-32 rounded-lg bg-default-300"></div>
                  </Skeleton>
                  <div className="space-y-3">
                    <Skeleton className="w-4/5 rounded-lg">
                      <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                    </Skeleton>
                    <Skeleton className="w-2/5 rounded-lg">
                      <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                    </Skeleton>
                  </div>
                </Card>
              ))
            : posts.map((e, i) => (
                <PostCard
                  key={i}
                  post={e}
                  minimumVersion
                  className="p-4 md:p-6 w-[330px] sm:w-[360px] md:w-[460px] Xh-[356px] border-1 rounded-3xl"
                  slot={
                    "tipo" in e ? (
                      <>
                        <Chip
                          variant="flat"
                          color="default"
                          className={`hidden md:flex mt-2 font-semibold  items-center ${
                            e.tipo == "reacciones"
                              ? "bg-pink-500/30 text-pink-500"
                              : e.tipo == "publicaciones"
                              ? "bg-primary/30 text-primary"
                              : "bg-blue-400/30 text-blue-500"
                          }`}
                        >
                          <span className=" font-semibold capitalize ">
                            {e.tipo}
                          </span>
                        </Chip>
                      </>
                    ) : (
                      <></>
                    )
                  }
                  slotDescription={
                    "tipo" in e ? (
                      <p className="font-medium mt-3">
                        <span className="w-full ">
                          {e.tipo == "reacciones"
                            ? "Reaccionó a esta publicación ❤️"
                            : e.tipo == "publicaciones"
                            ? "Nueva publicación ✨"
                            : "Comentó esta publicación 💭"}
                        </span>
                      </p>
                    ) : (
                      <></>
                    )
                  }
                />
              ))}
        </div>
      </div>
      <div className="w-full flex justify-center gap-8 mt-8 md:mt-14">
        <SecondaryButton
          className="font-semibold text-[14px] text-color4"
          onClick={handleScrollLeftPosts}
        >
          <ArrowLeftIcon />
        </SecondaryButton>
        <SecondaryButton
          className="font-semibold text-[14px] text-color4"
          onClick={handleScrollRightPosts}
        >
          <ArrowRightIcon />
        </SecondaryButton>
      </div>
    </div>
  );
}
