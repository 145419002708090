import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function getCountries() {
  try {
    const response = (await axiosClient.get(Api.country.index)).data;
    return response?.info?.data ?? [];
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
