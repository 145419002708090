import Logo from "../components/common/Logo";
import CustomInput from "../components/form/CustomInput";
import PrimaryButton from "../components/common/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { FormEvent, useState } from "react";
import LoginImage from "../components/auth/LoginImage";
import { resetPassword, updateUser } from "../api/auth";
import VerifyEmail from "../components/auth/VerifyEmail";
import { toast } from "react-toastify";
import { useLoginStore } from "../stores/loginDataStore";

export default function RecoveryPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [verifyModal, setVerifyModal] = useState(false);
  const user = useLoginStore((state) => state.user);

  const [showResetPass, setShowResetPass] = useState(false);

  const [data, setData] = useState({
    password: "",
    repeatPassword: "",
  });

  const navigate = useNavigate();

  async function submit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    //const formData = new FormData(e.target as HTMLFormElement);
    setLoading(true);

    //Data for request
    const data = {
      email: email,
    };

    try {
      const response = await resetPassword(data);
      // console.log(response);
      if (response.error == "true") {
        toast.error(response.info.message);
      } else {
        toast.success(response.info.message);
        setVerifyModal(true);
      }
    } catch (error) {
      console.error(error);
      toast.error("Ha ocurrido un error desconocido");
    }

    //console.log(data);
    setLoading(false);
  }

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setEmail(data.value);
  };
  const handleChangePass = (e: FormEvent<HTMLInputElement>) => {
    const passData = e.target as HTMLInputElement;
    setData({
      ...data,
      [passData.name]: passData.value,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (data.password != data.repeatPassword) {
      toast.error("Las contraseñas no coinciden");
      return;
    }

    setLoading(true);

    //Data for request
    const payload = {
      password: data.password,
    };

    if (user)
      try {
        const response = await updateUser(user.id, payload);
        // console.log(response);
        if (response.error == "true") {
          toast.error(response.info.message);
        } else {
          toast.success(response.info.message);
          setVerifyModal(true);
          navigate("/login");
        }
      } catch (error) {
        console.error(error);
        toast.error("Ha ocurrido un error desconocido");
      }

    //console.log(data);
    setLoading(false);
  };

  return (
    <>
      <VerifyEmail
        // token={token}
        isOpen={verifyModal}
        email={email}
        closeModal={() => setVerifyModal(false)}
        next={() => {
          setShowResetPass(true);
        }}
        isForResetPass
      />
      <div className="w-full flex max-w-[1554px] h-screen justify-center">
        <div className="flex row p-3 w-full items-center">
          <section className="col-12 xl:col-7 flex justify-center items-center h-full ">
            {showResetPass ? (
              <form
                onSubmit={handleSubmit}
                className="max-w-[360px] w-full h-max text-center"
              >
                <div className="flex justify-center">
                  <Logo />
                </div>
                <h1 className="mt-6 font-semibold text-[30px] ">
                  Recuperar cuenta
                </h1>
                <h3 className="mt-2 font-normal text-color1 text-[16px]">
                  Introduce una contraseña nueva
                </h3>
                <CustomInput
                  required={showResetPass}
                  type="password"
                  name="password"
                  label="Nueva contraseña"
                  placeholder="Introduce tu contraseña"
                  className="pt-5"
                  value={data.password}
                  onChange={handleChangePass}
                />
                <CustomInput
                  required={showResetPass}
                  type="password"
                  name="repeatPassword"
                  label="Repite la contraseña"
                  placeholder="Introduce tu contraseña"
                  className="pt-5"
                  value={data.repeatPassword}
                  onChange={handleChangePass}
                />
                <PrimaryButton
                  type="submit"
                  loading={loading}
                  className="mt-[20px] w-full"
                >
                  Recuperar contraseña
                </PrimaryButton>
                <div className="mt-[28px] flex justify-center gap-1">
                  <p className="font-normal text-[14px] text-color5">
                    ¡Quiero iniciar sesión!
                  </p>
                  <Link
                    className="font-semibold text-[14px] text-color4"
                    to="/login"
                  >
                    Iniciar sesión
                  </Link>
                </div>
              </form>
            ) : (
              <form
                onSubmit={submit}
                className="max-w-[360px] h-max text-center"
              >
                <div className="flex justify-center">
                  <Logo />
                </div>
                <h1 className="mt-6 font-semibold text-[30px] ">
                  Recuperar cuenta
                </h1>
                <h3 className="mt-2 font-normal text-color1 text-[16px]">
                  Introduce tu correo electrónico para recuperar tu contraseña
                </h3>
                <CustomInput
                  required
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Introduce tu correo electrónico"
                  className="pt-[28px]"
                  value={email}
                  onChange={handleChange}
                />
                <PrimaryButton
                  type="submit"
                  loading={loading}
                  className="mt-[20px] w-full"
                >
                  Recuperar contraseña
                </PrimaryButton>
                <div className="mt-[28px] flex justify-center gap-1">
                  <p className="font-normal text-[14px] text-color5">
                    ¡Quiero iniciar sesión!
                  </p>
                  <Link
                    className="font-semibold text-[14px] text-color4"
                    to="/login"
                  >
                    Iniciar sesión
                  </Link>
                </div>
              </form>
            )}
          </section>
          <section className="hidden xl:flex xl:col-5 justify-end items-center p-5 pl-0">
            <div className="relative py-3 w-[720px] h-[calc(100vh-20px)] max-h-[920px] flex items-center">
              <LoginImage />
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
