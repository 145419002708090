import { Details, HowAmI, Others } from "../interfaces/SingleUser";

export interface StepTwoData extends Details {
  fantasias_id: string; //number[];
  provincia_id: number;
  nacionalidad_id?: string;
  partner_nacionalidad_id?: string;
  is_couple: boolean;
}
export interface StepThreeData extends HowAmI {}
export interface StepFourData extends Others {
  horoscopo_id: string;
  idiomas_id: string;
  profesion_id: string;
}

export interface LocalData {
  owners_name: string;
  locals_name: string;
  movil: string;
  CIF: string;
  direccion: string;
  ubicacion: string;
}

export const formatDate = (date: Date) => {
  // console.log(date);
  let d = date.getDate().toString();
  if (d.length == 1) d = "0" + d;
  let m = (date.getMonth() + 1).toString();
  if (m.length == 1) m = "0" + m;
  let y = (date.getFullYear() - 18).toString();
  return `${y}-${m}-${d}`;
};

export const StepTwoInitialState: StepTwoData = {
  fantasias_id: "",
  provincia_id: 0,
  is_couple: false,
  sex: {
    value: "f",
    visibility: "all",
  },
  partner_sex: {
    value: "",
    visibility: "all",
  },
  sexualOrentation: {
    value: "",
    visibility: "all",
  },
  partner_sexualOrentation: {
    value: "",
    visibility: "all",
  },
  birthDate: formatDate(new Date()),
  partner_birthDate: formatDate(new Date()),
  description: {
    value: "",
    visibility: "all",
  },
  partner_description: {
    value: "",
    visibility: "all",
  },
};
export const StepThreeInitialState: StepThreeData = {
  race: {
    value: "",
    visibility: "all",
  },
  partner_race: {
    value: "",
    visibility: "all",
  },
  height: {
    value: "",
    visibility: "all",
  },
  partner_height: {
    value: "",
    visibility: "all",
  },
  weight: {
    value: "",
    visibility: "all",
  },
  partner_weight: {
    value: "",
    visibility: "all",
  },
  complexion: {
    value: "",
    visibility: "all",
  },
  partner_complexion: {
    value: "",
    visibility: "all",
  },
  eye_color: {
    value: "",
    visibility: "all",
  },
  partner_eye_color: {
    value: "",
    visibility: "all",
  },
  hair_color: {
    value: "",
    visibility: "all",
  },
  partner_hair_color: {
    value: "",
    visibility: "all",
  },
  hair_length: {
    value: "",
    visibility: "all",
  },
  partner_hair_length: {
    value: "",
    visibility: "all",
  },
  type_of_nose: {
    value: "",
    visibility: "all",
  },
  partner_type_of_nose: {
    value: "",
    visibility: "all",
  },
  skin_color: {
    value: "",
    visibility: "all",
  },
  partner_skin_color: {
    value: "",
    visibility: "all",
  },
  pubic_hair_cut: {
    value: "",
    visibility: "all",
  },
  partner_pubic_hair_cut: {
    value: "",
    visibility: "all",
  },
  piercings: {
    value: "",
    visibility: "all",
  },
  partner_piercings: {
    value: "",
    visibility: "all",
  },
  tattoos: {
    value: "",
    visibility: "all",
  },
  partner_tattoos: {
    value: "",
    visibility: "all",
  },
  you_drink: {
    value: "",
    visibility: "all",
  },
  partner_you_drink: {
    value: "",
    visibility: "all",
  },
  you_smoke: {
    value: "",
    visibility: "all",
  },
  partner_you_smoke: {
    value: "",
    visibility: "all",
  },
  lips: {
    value: "",
    visibility: "all",
  },
  partner_lips: {
    value: "",
    visibility: "all",
  },
};
export const StepFourInitialState: StepFourData = {
  horoscopo_id: "",
  idiomas_id: "",
  profesion_id: "",
  partner_idioma_id: {
    value: "",
    visibility: "all",
  },
  partner_horoscopo_id: {
    value: "",
    visibility: "all",
  },
  partner_profesion_id: {
    value: "",
    visibility: "all",
  },
  children: {
    value: "",
    visibility: "all",
  },
  partner_children: {
    value: "",
    visibility: "all",
  },
  member_measurement: {
    value: "",
    visibility: "all",
  },
  partner_member_measurement: {
    value: "",
    visibility: "all",
  },
  nipple_size: {
    value: "",
    visibility: "all",
  },
  partner_nipple_size: {
    value: "",
    visibility: "all",
  },
  possibility_of_moving: {
    value: "",
    visibility: "all",
  },
  possibility_of_hosting: {
    value: "",
    visibility: "all",
  },
};

export const localInitialState: LocalData = {
  owners_name: "",
  locals_name: "",
  movil: "",
  CIF: "",
  direccion: "",
  ubicacion: "",
};
