import { Badge } from "@nextui-org/react";
import UserAvatar from "../common/UserAvatar";
import { User } from "../../interfaces/Login";
import { useAuthStore } from "../../stores/authStore";
import { EditIcon } from "../../assets/icons/PencilIcon";
import BuildingIcon from "../../assets/icons/BuildingIcon";
import { UsersIcon } from "../../assets/icons/UsersIcon";
import { GenderFemale, GenderMale } from "../../assets/icons/UserIcon";

export default function ProfileAvatar({
  userData,
  onClick,
  onEdit,
}: {
  userData: User | null;
  onClick: () => void;
  onEdit: () => void;
}) {
  const authData = useAuthStore((state) => state.user);

  return (
    <>
      {userData?.id == authData?.id ? (
        <>
          <UserAvatar
            user={userData}
            onClick={onClick}
            classes="w-[100px] h-[100px] xl:w-[114px] xl:h-[114px] border-4 border-white cursor-pointer"
          />
          <div
            onClick={onEdit}
            className="absolute right-2 top-[60px] xl:top-[70px] xl:right-2 bg-white/60 p-1 rounded-full cursor-pointer"
          >
            <EditIcon height={24} width={24} fill={"#344054"} />
          </div>
        </>
      ) : (
        <Badge
          isOneChar
          content={
            userData?.role_id == "3" ? (
              <BuildingIcon height={16} width={16}></BuildingIcon>
            ) : userData?.info?.is_couple ? (
              <UsersIcon stroke="white" />
            ) : userData?.info?.detalles?.sex?.value == "f" ? (
              <GenderFemale onClick={() => console.log(userData?.info)} />
            ) : (
              <GenderMale />
            )
          }
          color="primary"
          size="lg"
          shape="circle"
          placement="bottom-right"
          className={`z-20 bottom-5  ${
            userData?.role_id == "3"
              ? "bg-green-500"
              : userData?.info?.is_couple
              ? "bg-primary"
              : userData?.info?.detalles?.sex?.value == "f"
              ? "bg-pink-400"
              : "bg-blue-400"
          }`}
        >
          <UserAvatar
            user={userData}
            onClick={onClick}
            classes="w-[100px] h-[100px] xl:w-[114px] xl:h-[114px] border-4 border-white cursor-pointer"
          />
        </Badge>
      )}
    </>
  );
}
