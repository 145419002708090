import { Modal, ModalContent } from "@nextui-org/react";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import CustomInput from "../form/CustomInput";
import CustomDateInput from "../form/CustomDateInput";
import PremiumImage from "../../assets/premium-image.png";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import {
  ApplePayIcon,
  MasterCardIcon,
  VisaIcon,
} from "../../assets/icons/CardIcon";
import { ReactNode, useState } from "react";
import { CheckIcon, UnCheckedIcon } from "../../assets/icons/CheckIcon";
import { createSubscription } from "../../api/mine";
import { toast } from "react-toastify";
import { useAuthStore } from "../../stores/authStore";
import { SubscriptionRequest } from "../../interfaces/Login";

const cards = [
  {
    id: 1,
    name: "Visa ending in 1234",
    expiry: "06/2024",
    icon: <VisaIcon />,
  },
  {
    id: 2,
    name: "Mastercard ending in 1234",
    expiry: "06/2024",
    icon: <MasterCardIcon />,
  },
  {
    id: 3,
    name: "Visa ending in 1234",
    expiry: "06/2024",
    icon: <ApplePayIcon />,
  },
];

function BankCard({
  title,
  selected,
  icon,
  expiry,
  id,
}: {
  id: number;
  title: string;
  expiry: string;
  icon: ReactNode;
  selected: number;
}) {
  return (
    <div className="flex p-3 lg:w-[512px] h-[100px] border-2 border-color4 rounded-xl">
      <div className="p-2 pt-2.5 pr-5">{icon}</div>
      <div className="flex justify-between w-full">
        <div className="flex flex-col text-[14px]">
          <label
            className={`vtext-sm  ${
              selected == id ? "text-color6" : " text-color1"
            }`}
          >
            {title}
          </label>
          <label
            className={`-mt-0.5 font-normal  ${
              selected == id ? "text-color4" : " text-color1"
            }`}
          >
            Expiry {expiry}
          </label>
          <div className="mt-2 flex gap-3 font-semibold text-[14px] ">
            <label
              className={`cursor-pointer  ${
                selected == id ? "text-color4" : " text-color1"
              }`}
            >
              Set as default
            </label>
            <label className="cursor-pointer">Edit</label>
          </div>
        </div>
        <div hidden={selected != id}>
          <CheckIcon width={18} height={18} />
        </div>
        <div hidden={selected == id}>
          <UnCheckedIcon width={18} height={18} />
        </div>
      </div>
    </div>
  );
}

function PayDetails({
  className = "lg:block hidden w-[292px] rounded-xl border-2 border-gray-300 p-4",
  plan,
}: {
  className?: string;
  plan: SubscriptionRequest;
}) {
  return (
    <div className={className}>
      <div className="h-[201px]">
        <div className="absolute bg-color4 font-semibold text-[12px] text-white rounded-lg pt-1 pb-1 pl-2 pr-2 mt-2.5 ml-2.5">
          Orgías
        </div>
        <img
          src={PremiumImage}
          className="w-[260px] h-[201px] object-cover rounded-lg"
        />
      </div>
      <div className="mt-6 flex flex-col">
        <h3 className="uppercase font-bold text-[12px] text-color3">
          Resumen de compra
        </h3>
        <h3 className="mt-3 font-bold text-[16px] text-color3">
          {plan.anual ? "Plan Anual" : "Plan Mensual"}
        </h3>
      </div>
      <div className="mt-2.5 flex justify-between font-medium text-[24px] text-color3">
        <h3>Total</h3>
        <label>{plan.anual ? "125" : "15"}€</label>
      </div>
    </div>
  );
}

export default function PaymentInformationModal({
  isOpen = false,
  closeModal = () => {},
  plan,
}: {
  isOpen: boolean;
  closeModal?: () => void;
  plan: SubscriptionRequest;
}) {
  const setUserData = useAuthStore((state) => state.saveUser);
  const authData = useAuthStore((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const makePayment = async () => {
    try {
      setIsLoading(true);
      const res = await createSubscription(plan);
      setIsLoading(false);
      // console.log(res);
      if (res.error == "false") {
        if (authData)
          setUserData({
            ...authData,
            is_payed: true,
          });
        closeModal();
        toast.success("Pago realizado con éxito");
      } else {
        toast.error("Ha ocurrido un error pagado la suscripción");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [selected, setSelected] = useState(1);

  return (
    <Modal
      scrollBehavior="outside"
      className="lg:max-w-[852px] max-w-[560px] w-max"
      isOpen={isOpen}
      placement="center"
      onClose={closeModal}
      classNames={{
        closeButton: "mt-4 mr-4 lg:right-[292px]",
        base: "rounded-xl border-none",
      }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <div className="w-full">
          <div className="w-full flex">
            <div className="p-6">
              <div className="flex justify-start">
                <div className="text-start">
                  <div>
                    <svg
                      width="52"
                      height="52"
                      viewBox="0 0 52 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_367_7534)">
                        <rect
                          x="2"
                          y="1"
                          width="48"
                          height="48"
                          rx="10"
                          fill="white"
                        />
                        <path
                          d="M36 23H16M36 24V21.2C36 20.0799 36 19.5198 35.782 19.092C35.5903 18.7157 35.2843 18.4097 34.908 18.218C34.4802 18 33.9201 18 32.8 18H19.2C18.0799 18 17.5198 18 17.092 18.218C16.7157 18.4097 16.4097 18.7157 16.218 19.092C16 19.5198 16 20.0799 16 21.2V28.8C16 29.9201 16 30.4802 16.218 30.908C16.4097 31.2843 16.7157 31.5903 17.092 31.782C17.5198 32 18.0799 32 19.2 32H25.5M32 34C32 34 35 32.5701 35 30.4252V27.9229L32.8124 27.1412C32.2868 26.9529 31.712 26.9529 31.1864 27.1412L29 27.9229V30.4252C29 32.5701 32 34 32 34Z"
                          stroke="#344054"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_367_7534"
                          x="0"
                          y="0"
                          width="52"
                          height="52"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="1" />
                          <feGaussianBlur stdDeviation="1" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_367_7534"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_367_7534"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <h3 className="mt-2 font-semibold text-color3 text-[18px]">
                    Información de pago
                  </h3>
                  <p className="mt-1 font-normal text-[14px] text-color5">
                    Introduce tus datos para realizar el pago
                  </p>
                </div>
              </div>
              <div className="w-full h-[300px] overflow-hidden overflow-y-auto">
                <div className="mt-5 flex flex-col gap-3 justify-start">
                  {cards.map((card) => (
                    <div onClick={() => setSelected(card.id)} key={card.id}>
                      <BankCard
                        selected={selected}
                        id={card.id}
                        expiry={card.expiry}
                        title={card.name}
                        icon={card.icon}
                      />
                    </div>
                  ))}
                </div>
                <div className="mt-8 flex flex-col gap-3 justify-center">
                  <div className="flex w-full gap-4">
                    <CustomInput
                      label="Name on card"
                      placeholder="Introduce el nombre"
                    />
                    <CustomDateInput
                      disableVisibility
                      className="-mt-0.5"
                      title="Expiry"
                    />
                  </div>
                  <div className="flex w-full gap-4">
                    <CustomInput
                      startIcon={<MasterCardIcon />}
                      label="Card number"
                      placeholder="0000 0000 0000 0000"
                    />
                    <CustomInput
                      className="w-24"
                      label="CVV"
                      placeholder="000"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <PayDetails
                  plan={plan}
                  className="mt-8 max-lg:block hidden w-max rounded-xl border-2 border-gray-300 p-4"
                />
              </div>
              <div className="mt-8 flex justify-center gap-[12px]">
                <SecondaryButton
                  disabled={isLoading}
                  className="w-full"
                  onClick={closeModal}
                >
                  Cancelar
                </SecondaryButton>
                <PrimaryButton
                  className="w-full"
                  onClick={makePayment}
                  loading={isLoading}
                >
                  Realizar pago
                </PrimaryButton>
              </div>
            </div>
            <PayDetails plan={plan} />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
