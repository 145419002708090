import { create } from "zustand";
import {
  StepTwoData,
  StepThreeData,
  StepFourData,
  StepTwoInitialState,
  StepThreeInitialState,
  StepFourInitialState,
  LocalData,
  localInitialState,
} from "./initialStates";

interface RegisterState {
  step: number;
  amountOfParticipants: 1 | 2;
  stepTwoData: StepTwoData;
  stepThreeData: StepThreeData;
  stepFourData: StepFourData;
  localData: LocalData;
  setStep: (val: number) => void;
  setAmountOfParticipants: (val: 1 | 2) => void;
  setStepTwoData: (val: StepTwoData) => void;
  setStepThreeData: (val: StepThreeData) => void;
  setStepFourData: (val: StepFourData) => void;
  setLocalData: (val: LocalData) => void;
  resetData: () => void;
}

export const useRegisterStore = create<RegisterState>()((set) => ({
  step: 0,
  amountOfParticipants: 1,
  stepTwoData: StepTwoInitialState,
  stepThreeData: StepThreeInitialState,
  stepFourData: StepFourInitialState,
  localData: localInitialState,
  setStep: (by) => set(() => ({ step: by })),
  setAmountOfParticipants: (val) => set(() => ({ amountOfParticipants: val })),
  setStepTwoData: (val) => set(() => ({ stepTwoData: val })),
  setStepThreeData: (val) => set(() => ({ stepThreeData: val })),
  setStepFourData: (val) => set(() => ({ stepFourData: val })),
  setLocalData: (val) => set(() => ({ localData: val })),
  resetData: () =>
    set(() => ({
      stepTwoData: StepTwoInitialState,
      stepFourData: StepFourInitialState,
      stepThreeData: StepThreeInitialState,
    })),
}));

export function getInfoPayload(is_couple: number, id?: number) {
  //useUserStore.getState().user?.info?.detalles
  const stepTwoData = useRegisterStore.getState().stepTwoData;
  const stepThreeData = useRegisterStore.getState().stepThreeData;
  const stepFourData = useRegisterStore.getState().stepFourData;

  const data = {
    user_id: id,
    fantasias_id: stepTwoData.fantasias_id,
    horoscopo_id: stepFourData.horoscopo_id,
    provincia_id: stepTwoData.provincia_id,
    // looking_id: "",
    idiomas_id: stepFourData.idiomas_id,
    profesion_id: stepFourData.profesion_id,
    nacionalidad_id: stepTwoData.nacionalidad_id,
    is_couple: is_couple,
    detalles: JSON.stringify({
      sex: stepTwoData.sex,
      sexualOrentation: stepTwoData.sexualOrentation,
      birthDate: stepTwoData.birthDate,
      description: stepTwoData.description,
      nacionalidad_id: stepTwoData.nacionalidad_id,
      //partner
      partner_sex: stepTwoData.partner_sex,
      partner_sexualOrentation: stepTwoData.partner_sexualOrentation,
      partner_birthDate: stepTwoData.partner_birthDate,
      partner_description: stepTwoData.partner_description,
      partner_nacionalidad_id: stepTwoData.partner_nacionalidad_id,
    }),
    como_soy: JSON.stringify({
      race: stepThreeData.race,
      height: stepThreeData.height,
      weight: stepThreeData.weight,
      complexion: stepThreeData.complexion,
      eye_color: stepThreeData.eye_color,
      hair_color: stepThreeData.hair_color,
      hair_length: stepThreeData.hair_length,
      type_of_nose: stepThreeData.type_of_nose,
      skin_color: stepThreeData.skin_color,

      pubic_hair_cut: stepThreeData.pubic_hair_cut,
      piercings: stepThreeData.piercings,
      tattoos: stepThreeData.tattoos,
      you_drink: stepThreeData.you_drink,
      you_smoke: stepThreeData.you_smoke,
      lips: stepThreeData.lips,
      // partner
      partner_race: stepThreeData.partner_race,
      partner_height: stepThreeData.partner_height,
      partner_weight: stepThreeData.partner_weight,
      partner_complexion: stepThreeData.partner_complexion,
      partner_eye_color: stepThreeData.partner_eye_color,
      partner_hair_color: stepThreeData.partner_hair_color,
      partner_hair_length: stepThreeData.partner_hair_length,
      partner_type_of_nose: stepThreeData.partner_type_of_nose,
      partner_skin_color: stepThreeData.partner_skin_color,

      partner_pubic_hair_cut: stepThreeData.partner_pubic_hair_cut,
      partner_piercings: stepThreeData.partner_piercings,
      partner_tattoos: stepThreeData.partner_tattoos,
      partner_you_drink: stepThreeData.partner_you_drink,
      partner_you_smoke: stepThreeData.partner_you_smoke,
      partner_lips: stepThreeData.partner_lips,
    }),
    otros: JSON.stringify({
      children: stepFourData.children,
      member_measurement: stepFourData.member_measurement,
      nipple_size: stepFourData.nipple_size,
      possibility_of_moving: stepFourData.possibility_of_moving,
      possibility_of_hosting: stepFourData.possibility_of_hosting,
      profesion_visibilty: "all", //TODO: fix this
      //Partner
      partner_profesion_id: stepFourData.partner_profesion_id,
      partner_idioma_id: stepFourData.partner_idioma_id,
      partner_horoscopo_id: stepFourData.partner_horoscopo_id,
      partner_children: stepFourData.partner_children,
      partner_member_measurement: stepFourData.partner_member_measurement,
      partner_nipple_size: stepFourData.partner_nipple_size,
    }),
  };

  return data;
}
