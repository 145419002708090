import { User } from "@nextui-org/react";
import { ChatsResponse } from "../../interfaces/Chat";
import { useMemo } from "react";
import { useAuthStore } from "../../stores/authStore";

export default function ChatList({
  chats,
  onChatSelected,
  chatSelected,
}: {
  chats: ChatsResponse[];
  onChatSelected: (id: string) => void;
  chatSelected: string;
}) {
  return (
    <>
      {chats.map((it) => (
        <div
          onClick={() => onChatSelected(it.id.toString())}
          key={it.id}
          className={`w-full p-1 py-2 ${
            it.id.toString() == chatSelected
              ? " bg-primary/10 border-b-2 border-primary "
              : "border-b-2 border-zinc-100 cursor-pinter "
          }`}
        >
          <ChatItem chat={it} />
        </div>
      ))}
    </>
  );
}

function ChatItem({ chat }: { chat: ChatsResponse }) {
  const authData = useAuthStore((state) => state.user);
  const chatUser = useMemo(() => {
    if (chat && chat.integrantes) {
      let member = chat.integrantes.find((e) => e.user_id != authData?.id);
      if (member) return member.user;
    }
    return undefined;
  }, [chat]);

  const getLastContent = () => {
    if (chat) {
      return `${chat.lastMessage.user_id == authData?.id ? "Tú: " : ""}${
        chat.lastMessage.media == "image"
          ? "Foto..."
          : chat.lastMessage.content.slice(0, 24)
      }
          ${
            chat.lastMessage.content.length > 24 &&
            chat.lastMessage.media == "text"
              ? "..."
              : ""
          }`;
    }

    return undefined;
  };

  return (
    <User
      name={chatUser ? chatUser?.name : "Desconocido"}
      description={getLastContent()}
      avatarProps={{
        className: "border-[3px] border-white shadow-lg",
        src: chatUser?.profile_path
          ? chatUser?.profile_path?.startsWith("http")
            ? chatUser?.profile_path
            : import.meta.env.VITE_BASE_URL + chatUser?.profile_path
          : undefined,
      }}
    />
  );
}
