import { useEffect, useState } from "react";
import PostImage from "../post/PostImage";
import { Post } from "../../interfaces/Post";
import { User } from "../../interfaces/Login";
import { getPublicationsByUser } from "../../api/publication";
import { toast } from "react-toastify";
import { Card, Skeleton } from "@nextui-org/react";

export default function Galery({ user }: { user: User }) {
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      // get media publications
      const data = await getPublicationsByUser({ user_id: user.id }, true);
      if (data?.error == "true" || !data) {
        toast.error(data.info.message + " ");
      }
      if (data?.info?.data[0]?.data) {
        setPosts(data.info.data[0].data);
        // console.log("postsRes.info.data[0]", postsRes.info.data[0].data);
      }
      setIsLoading(false);
    };

    getData();
  }, [user]);
  return (
    <>
      <div className="mt-10 flex justify-start px-6">
        <h3 className="font-bold text-[18px] text-color3 mb-10">Galería</h3>
      </div>
      <div className=" flex justify-start px-6">
        {!isLoading && posts.length == 0 && (
          <p>No hay imágenes que mostrar 😕</p>
        )}
      </div>

      <div className="w-full gap-2 grid grid-cols-12 grid-rows-2 px-8">
        {isLoading
          ? [1, 2, 3, 4, 5, 6].map((post) => (
              <Card
                key={post}
                className="col-span-12 sm:col-span-6 md:col-span-4  h-[300px]  p-2 rounded-xl shadow-none border-1 border-gray-200"
              >
                <Skeleton className="rounded-lg">
                  <div className="h-56 rounded-lg bg-default-300"></div>
                </Skeleton>
              </Card>
            ))
          : posts.map((post) => <PostImage key={post.id} post={post} />)}
      </div>
    </>
  );
}
