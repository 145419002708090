import { SVGProps } from "react";

export function CopyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <g
        fill="none"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      >
        <rect width="144" height="144" x="16" y="16" ry="32"></rect>
        <path d="M 95.999712,127.9996 A 31.999891,31.999891 0 0 1 127.9996,95.999712"></path>
        <path
          d="m -239.99922,127.9996 a 31.999891,31.999891 0 0 1 31.99989,-31.999888"
          transform="scale(-1 1)"
        ></path>
        <path
          d="m -239.99922,-207.99933 a 31.999891,31.999891 0 0 1 31.99989,-31.99989"
          transform="scale(-1)"
        ></path>
        <path
          d="M 95.999712,-207.99933 A 31.999891,31.999891 0 0 1 127.9996,-239.99922"
          transform="scale(1 -1)"
        ></path>
        <path d="m 159.99949,239.99923 h 15.99995"></path>
        <path d="m 159.99949,95.9997 h 15.99995"></path>
        <path d="m 95.999709,159.99949 v 15.99995"></path>
        <path d="m 239.99923,159.99949 v 15.99995"></path>
      </g>
    </svg>
  );
}
