export default function FileInput({
  className = "mt-5 w-[320px] lg:w-[400px] min-h-[200px] rounded-xl border-2 border-color4 cursor-pointer overflow-hidden",
  onClick = () => {},
  file = "",
  type = "image",
}: {
  className?: string;
  onClick?: () => void;
  file?: string;
  type?: "image" | "video";
}) {
  return (
    <div
      className={`${className} ${file ? "" : "h-[200px]"}`}
      onClick={onClick}
    >
      {file.length && type == "image" ? (
        <div
          className={`flex w-full h-[200px] justify-center bg-center bg-cover `}
          style={{ backgroundImage: ` url(${file})` }}
        ></div>
      ) : file.length && type == "video" ? (
        <>
          <div className="flex w-full h-[200px] justify-center">
            <video
              controls
              className="w-ful z-0 w-auto object-cover brightness-50"
            >
              <source src={file} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </>
      ) : (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <div className="flex justify-center ">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.6665 13.3333L9.99984 10M9.99984 10L13.3332 13.3333M9.99984 10V17.5M16.6665 13.9524C17.6844 13.1117 18.3332 11.8399 18.3332 10.4167C18.3332 7.88536 16.2811 5.83333 13.7498 5.83333C13.5677 5.83333 13.3974 5.73833 13.3049 5.58145C12.2182 3.73736 10.2119 2.5 7.9165 2.5C4.46472 2.5 1.6665 5.29822 1.6665 8.75C1.6665 10.4718 2.36271 12.0309 3.48896 13.1613"
                stroke="#475467"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="mt-4 flex justify-center">
            <p className="font-normal text-[14px] text-color5">
              <span className="text-color4 font-semibold">
                Click para subir{" "}
              </span>
              o arrastra {type == "image" ? "la foto" : "el video"}
            </p>
          </div>
          <div className="mt-1 flex justify-center">
            <p className="font-normal text-[12px] text-color5">
              {type == "image" ? "PNG o JPG" : "Video"} (max.{" "}
              {type == "image" ? 7 : 50} MB)
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
