import { Card, CardBody, CardFooter, Chip, Image } from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import { Link } from "react-router-dom";
import { PartyRequest } from "../../interfaces/Party";
import { useState } from "react";
import { respondRequestJoinParty } from "../../api/party";
import { toast } from "react-toastify";

export default function PartyRequestCard({
  request,
  onRequestAction,
}: {
  request: PartyRequest;
  onRequestAction: (type: string, req: PartyRequest) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onAcceptRequest = async () => {
    setIsLoading(true);
    const payload = {
      accion: "aprobar",
    };
    try {
      const res = await respondRequestJoinParty(payload, request.id);

      if (res?.error == "true" || !res.info) {
        toast.error("Ha ocurrido un error");
      } else {
        onRequestAction("aprobar", request);
        toast.success("Solicitud aceptada con éxito");
      }
    } catch (error) {
      console.error(error);
      toast.error("Ha ocurrido un error");
    }

    setIsLoading(false);
  };

  const onRejectRequest = async () => {
    setIsRejecting(true);
    const payload = {
      accion: "denegar",
    };
    try {
      const res = await respondRequestJoinParty(payload, request.id);

      if (res?.error == "true" || !res.info) {
        toast.error("Ha ocurrido un error");
      } else {
        onRequestAction("denegar", request);
        toast.success("Solicitud denegada con éxito");
      }
    } catch (error) {
      console.error(error);
      toast.error("Ha ocurrido un error");
    }

    setIsRejecting(false);
  };
  const onDeleteRequest = async () => {
    setIsDeleting(true);
    const payload = {
      accion: "eliminar",
    };
    try {
      const res = await respondRequestJoinParty(payload, request.id);

      if (res?.error == "true" || !res.info) {
        toast.error("Ha ocurrido un error");
      } else {
        onRequestAction("eliminar", request);
        toast.success("Solicitud eliminada con éxito");
      }
    } catch (error) {
      console.error(error);
      toast.error("Ha ocurrido un error");
    }

    setIsDeleting(false);
  };
  return (
    <Card shadow="sm" radius="md" className="w-full h-[280px]">
      <CardBody className="overflow-visible p-0">
        <Link to={`/profile/${request.user.id}`}>
          <Image
            shadow="none"
            radius="md"
            width="100%"
            isZoomed
            alt="alt"
            className="w-full object-cover h-[140px]"
            fallbackSrc="/assets/fantasyImage.jpeg"
            src={
              request.user?.profile_path
                ? request.user?.profile_path?.startsWith("http")
                  ? request.user?.profile_path
                  : import.meta.env.VITE_BASE_URL + request.user?.profile_path
                : undefined
            }
          />
        </Link>
      </CardBody>
      <CardFooter className="text-small flex flex-col items-start">
        <Link to={`/profile/${request.user.id}`}>
          <b className="hover:text-primary">{request.user.name}</b>
          <Chip
            variant="flat"
            color={
              request.estado == "aprobado"
                ? "primary"
                : request.estado == "pendiente"
                ? "warning"
                : "danger"
            }
            className="mt-2 ml-2 capitalize"
          >
            {request.estado}
          </Chip>
        </Link>
        <div className="w-full flex gap-2 mt-4">
          {request.estado == "pendiente" ||
            (request.estado == "aprobado" && (
              <SecondaryButton
                className="w-full"
                disabled={isLoading}
                loading={isRejecting}
                onClick={onRejectRequest}
              >
                Rechazar
              </SecondaryButton>
            ))}

          {request.estado == "pendiente" && request.invitacion == 0 && (
            <>
              <PrimaryButton
                className="w-full"
                loading={isLoading}
                onClick={onAcceptRequest}
              >
                Aceptar
              </PrimaryButton>
              <SecondaryButton
                className="w-full"
                disabled={isLoading}
                loading={isRejecting}
                onClick={onRejectRequest}
              >
                Rechazar
              </SecondaryButton>
            </>
          )}
          {request.estado == "pendiente" && request.invitacion == 1 && (
            <PrimaryButton
              className="w-full bg-red-600"
              loading={isDeleting}
              onClick={onDeleteRequest}
            >
              Cancelar
            </PrimaryButton>
          )}
        </div>
      </CardFooter>
    </Card>
  );
}
