export default function StepIcon({ className = "" }: { className?: string }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_304_3628)">
        <rect x="0.333496" width="24" height="24" rx="12" fill="#F9F5FF" />
        <rect
          x="1.0835"
          y="0.75"
          width="22.5"
          height="22.5"
          rx="11.25"
          stroke="#A24BFD"
          strokeWidth="1.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.43 7.39016L10.27 14.3002L8.37 12.2702C8.02 11.9402 7.47 11.9202 7.07 12.2002C6.68 12.4902 6.57 13.0002 6.81 13.4102L9.06 17.0702C9.28 17.4102 9.66 17.6202 10.09 17.6202C10.5 17.6202 10.89 17.4102 11.11 17.0702C11.47 16.6002 18.34 8.41016 18.34 8.41016C19.24 7.49016 18.15 6.68016 17.43 7.38016V7.39016Z"
          fill="#A24BFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_304_3628">
          <rect x="0.333496" width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
