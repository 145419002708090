import { SVGProps } from "react";

export function ChatIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_35_39685)">
        <path
          d="M8.33342 12.4993L5.7707 15.0941C5.41324 15.456 5.2345 15.637 5.08087 15.6498C4.94759 15.6608 4.81709 15.6073 4.73005 15.5057C4.62971 15.3887 4.62971 15.1343 4.62971 14.6256V13.3257C4.62971 12.8693 4.25598 12.5391 3.80442 12.473V12.473C2.71153 12.3129 1.85323 11.4546 1.69314 10.3617C1.66675 10.1815 1.66675 9.96645 1.66675 9.53638V5.66602C1.66675 4.26588 1.66675 3.56582 1.93923 3.03104C2.17892 2.56063 2.56137 2.17818 3.03177 1.9385C3.56655 1.66602 4.26662 1.66602 5.66675 1.66602H11.8334C13.2335 1.66602 13.9336 1.66602 14.4684 1.9385C14.9388 2.17818 15.3212 2.56063 15.5609 3.03104C15.8334 3.56582 15.8334 4.26588 15.8334 5.66602V9.16602M15.8334 18.3327L14.0197 17.0717C13.7648 16.8945 13.6373 16.8059 13.4986 16.743C13.3755 16.6873 13.246 16.6467 13.1131 16.6222C12.9633 16.5946 12.808 16.5946 12.4975 16.5946H11.0001C10.0667 16.5946 9.59995 16.5946 9.24343 16.4129C8.92983 16.2531 8.67486 15.9982 8.51507 15.6846C8.33342 15.3281 8.33342 14.8613 8.33342 13.9279V11.8327C8.33342 10.8993 8.33342 10.4326 8.51507 10.076C8.67486 9.76243 8.92983 9.50746 9.24343 9.34767C9.59995 9.16602 10.0667 9.16602 11.0001 9.16602H15.6667C16.6002 9.16602 17.0669 9.16602 17.4234 9.34767C17.737 9.50746 17.992 9.76243 18.1518 10.076C18.3334 10.4326 18.3334 10.8993 18.3334 11.8327V14.0946C18.3334 14.8712 18.3334 15.2594 18.2065 15.5657C18.0374 15.9741 17.7129 16.2986 17.3046 16.4677C16.9983 16.5946 16.61 16.5946 15.8334 16.5946V18.3327Z"
          stroke={props.stroke ?? "#667085"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_39685">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

/**
 */
export function MessageIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 8H10M6 11.5H13M10 19.5C14.9706 19.5 19 15.4706 19 10.5C19 5.52944 14.9706 1.5 10 1.5C5.02944 1.5 1 5.52944 1 10.5C1 11.6971 1.23374 12.8397 1.65806 13.8845C1.73927 14.0845 1.77988 14.1845 1.798 14.2653C1.81572 14.3443 1.8222 14.4028 1.82221 14.4839C1.82222 14.5667 1.80718 14.6569 1.77711 14.8374L1.18413 18.3952C1.12203 18.7678 1.09098 18.9541 1.14876 19.0888C1.19933 19.2067 1.29328 19.3007 1.41118 19.3512C1.54589 19.409 1.73218 19.378 2.10476 19.3159L5.66265 18.7229C5.84309 18.6928 5.9333 18.6778 6.01613 18.6778C6.09715 18.6778 6.15566 18.6843 6.23472 18.702C6.31554 18.7201 6.41552 18.7607 6.61549 18.8419C7.6603 19.2663 8.80286 19.5 10 19.5Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
