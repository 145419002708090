import { create } from "zustand";
import { Post } from "../interfaces/Post";

interface NorificationsState {
  count: number;
  setCount: (data: number) => void;
  notificationPost: Post | null;
  setNotificationPost: (data: Post | null) => void;
}

export const useNotificationsStore = create<NorificationsState>()((set) => ({
  count: 0,
  notificationPost: null,
  setCount: (data) => set(() => ({ count: data })),
  setNotificationPost: (data) => set(() => ({ notificationPost: data })),
}));
