import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Chat, ChatsResponse, Message } from "../interfaces/Chat";
import { uploadFile } from "../api/upload";
import { AxiosProgressEvent } from "axios";
import { sendMessageToUser } from "../api/chat";
import { socket } from "../utils/socket";

interface PendingMessg {
  file?: File;
  toUser: number;
  chatId: number;
  content: string;
  percentCompleted?: number;
  hasError?: boolean;
  isSending?: boolean;
}
interface ChatState {
  chats: ChatsResponse[];
  chatMessages: Chat[];
  newMessages: Message[];
  hasUnreadMessages: boolean;
  setChats: (data: ChatsResponse[]) => void;
  setChatMessages: (data: Chat[]) => void;
  setNewMessages: (data: Message[]) => void;
  setHasUnreadMessages: (data: boolean) => void;
  sendImage: (
    id: string,
    data: PendingMessg,
    callback: (value: number) => void
  ) => void;
  pendingMessg: { [key: string]: PendingMessg | undefined };
}
const initialMap = {};
export const useChatStore = create<ChatState>()(
  devtools(
    persist(
      (set, get) => ({
        chats: [],
        chatMessages: [],
        newMessages: [],
        hasUnreadMessages: false,
        setChats: (data) => set(() => ({ chats: data })),
        setChatMessages: (data) => set(() => ({ chatMessages: data })),
        setNewMessages: (data) => set(() => ({ newMessages: data })),
        setHasUnreadMessages: (data) =>
          set(() => ({ hasUnreadMessages: data })),
        pendingMessg: initialMap,
        sendImage: async (id, data, callback) => {
          //TODO: refactor this!!
          // console.log("get().pendingMessg", get().pendingMessg);
          let copy = get().pendingMessg;

          let messPd = {
            ...data,
            isSending: true,
            hasError: false,
            percentCompleted: 0,
          };
          copy[id] = messPd;
          set(() => ({ pendingMessg: copy }));
          //setIsSending(true);
          //setHasError(false);

          try {
            const formData = new FormData();
            const data = copy[id];
            if (data?.file) {
              formData.append("files[0]", data.file, data.file.name);
              formData.append("type", "image");
              formData.append("folder[0]", "chat");
            }

            const uploadResponse = await uploadFile(formData, {
              onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                const percent = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total!
                );
                messPd = {
                  ...messPd,
                  percentCompleted: percent,
                };
                callback(percent);
                copy[id] = messPd;
                set(() => ({ pendingMessg: copy }));
              },
            });

            if (uploadResponse?.error == "true" || !uploadResponse) {
              // toast.error(uploadResponse.info.message + " ");
              messPd = {
                ...messPd,
                isSending: false,
                hasError: true,
                percentCompleted: 0,
              };
              copy[id] = messPd;
              set(() => ({ pendingMessg: copy }));
              //  setIsSending(false);
              //  setHasError(true);
              return;
            }

            const uuid = Math.floor(Math.random() * 10000);
            if (data?.toUser) {
              await sendMessageToUser(data?.toUser, {
                content: uploadResponse.info.data[0].chat.file.content,
                media: "image",
                uuid: uuid.toString(),
              });
              // console.log("newMessage", newMessage);
            }

            //setIsSent(true);
            //  if (onImageSent)
            //    onImageSent(uploadResponse.info.data[0].chat.file.content);
            //setIsSending(false);
            if (data?.chatId) {
              const chatData = get().chatMessages.find(
                (e) => e?.id == data?.chatId
              );
              const url = uploadResponse.info.data[0].chat.file.content;

              if (chatData) {
                const messages = chatData.messages.data;
                const idx = messages.findIndex((e) => e.id.toString() == id);
                if (idx != -1) {
                  let messJustSent = chatData.messages.data[idx];

                  messJustSent = {
                    ...messJustSent,
                    file: undefined,
                    content: url,
                  };
                  messages.splice(idx, 1, messJustSent);
                  const chat = {
                    ...chatData,
                    messages: {
                      ...chatData.messages,
                      data: [...messages],
                    },
                  };
                  const chatMess = get().chats.find((e) => e.id == chatData.id);
                  socket.emit("message", {
                    clientId: data?.toUser,
                    message: [
                      {
                        id: (Math.random() * 100) / 100, //TODO: fix this when back is ready
                        user_id: messJustSent.user.id,
                        chat_id: chatMess?.isNewChat ? undefined : chatData.id,
                        content: url,
                        media: "image",
                        user: messJustSent.user,
                        uuid: uuid.toString(),
                        created_at: messJustSent.created_at,
                      },
                    ],
                  });
                  //handleSetChatMessages(id, chat);
                  const pos = get().chatMessages.findIndex(
                    (e) => e?.id == data?.chatId
                  );
                  const messagesToReplace = [...get().chatMessages];
                  if (pos != -1) {
                    messagesToReplace[pos] = chat;
                    // console.log("mess", messagesToReplace);
                    //  setChatsMessages(messagesToReplace);
                    set(() => ({ chatMessages: messagesToReplace }));
                  } else {
                    //setChatsMessages([...messagesToReplace, chat]);
                    set(() => ({
                      chatMessages: [...messagesToReplace, chat],
                    }));
                  }
                  messPd = {
                    ...messPd,
                    file: undefined,
                  };
                  copy[id] = messPd;
                  set(() => ({ pendingMessg: copy }));
                }
              }
            }
          } catch (error) {
            //setIsSending(false);
            console.error(error);
            //toast.error("Ha ocurrido un error");
          } finally {
            messPd = {
              ...messPd,
              isSending: false,
            };
            copy[id] = messPd;
            set(() => ({ pendingMessg: copy }));
          }
          // console.log(get().chatMessages);
        },
      }),
      { name: "chat" }
    )
  )
);
