import { DatePicker, DateValue } from "@nextui-org/react";
import VisibilityState from "./VisibilityState";
import { FormEvent } from "react";

export default function CustomDateInput({
  name = "",
  visibility_name = "",
  title = "Custom Date Input",
  required = false,
  onVisibilityChange = () => {},
  visibilityAll = false,
  disableVisibility = false,
  className = "",
  value = null,
}: {
  name?: string;
  visibility_name?: string;
  title?: string;
  required?: boolean;
  onVisibilityChange?: (value: string) => void;
  visibilityAll?: boolean;
  disableVisibility?: boolean;
  className?: string;
  value?: DateValue | null;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
}) {
  return (
    <div>
      <DatePicker
        name={name}
        variant="bordered"
        isRequired={required}
        label={title}
        className={"max-w-sm " + className}
        labelPlacement="outside"
        value={value}
        classNames={{
          base: " text-[14px] font-medium group-data-[filled-within=true]:text-color2",
          inputWrapper: "h-[44px] rounded-lg border-small",
          //popoverContent: "rounded-md",
        }}
        showMonthAndYearPickers
        hideTimeZone
      />
      <div hidden={disableVisibility}>
        <VisibilityState
          onVisibilityChange={onVisibilityChange}
          visibilityAll={visibilityAll}
          visibility_name={visibility_name}
        />
      </div>
    </div>
  );
}
