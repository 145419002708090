export const Api = {
  auth: {
    login: "/login",
    register: "/register",
    verifyCode: "/verifyCode",
    resendCode: "/verify",
    resetPass: "/reset",
  },
  mine: {
    home: "/home",
    subscription: "/suscription",
    search: "/search",
    customerPortal: "/getaccess",
  },
  info: {
    addInfo: "/addInfo",
  },
  fantasy: {
    fantasyList: "/fantasia",
  },
  horoscope: {
    horoscopeList: "/horoscopo",
  },
  langueage: {
    langueageList: "/idioma",
  },
  sexualOrientation: {
    orientationList: "/orientacion",
  },
  province: {
    provinceList: "/provincia",
  },
  profesion: {
    profesionList: "/profesion",
  },
  looking: {
    lookingForList: "/looking",
  },
  verify: {
    addPhoto: "/addPhoto",
  },
  publication: {
    post: "/publicacion",
    posts: "/getPosts",
    reaction: "/reaccion",
    comment: "/comentario",
    save: "/save",
    highlight: "/destacado",
  },
  upload: {
    uploadFile: "/uploads",
  },
  user: {
    getUser: "/user",
    report: "/reporte",
    activity: "/activity",
  },
  friends: {
    sendFriendRequest: "/amistad",
    getFriends: "/friend",
  },
  block: {
    index: "/block",
  },
  trip: {
    index: "/viaje",
  },
  party: {
    index: "/fiesta",
    request: "/request/fiesta",
    partyType: "/tipoFiesta",
  },
  notification: {
    index: "/notificacion",
  },
  country: {
    index: "/paises",
  },
  chat: {
    index: "chat",
    toUser: "chat/user",
    messages: "messages",
  },
  plans: {
    index: "plan",
  },
  reactions: {
    type: "tipo-reaccion",
  },
};
