import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function saveLocalInfo(data: {
  user_id: number;
  CIF: string;
  movil: string;
  locals_name: string;
  owners_name: string;
}) {
  try {
    return (await axiosClient.post(Api.info.addInfo, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function saveUserInfo(data: any) {
  try {
    const response = (await axiosClient.post(Api.info.addInfo, data)).data;
    // console.log(response);
    try {
      if (response?.info?.data["0"]?.info[0]) {
        response.info.data.user = response?.info?.data["0"];
        response.info.data.user.info = response.info?.data["0"]?.info[0];
      }

      if (response?.info?.data?.user?.info?.como_soy) {
        response.info.data.user.info.como_soy = JSON.parse(
          response.info?.data?.user?.info?.como_soy
        );
      }
      if (response?.info?.data?.user?.info?.detalles) {
        response.info.data.user.info.detalles = JSON.parse(
          response.info?.data?.user?.info?.detalles
        );
      }
      if (response?.info?.data?.user?.info?.otros) {
        response.info.data.user.info.otros = JSON.parse(
          response.info?.data?.user?.info?.otros
        );
      }
    } catch (error) {
      console.error(error);
    }

    // if (response.info?.data[0]?.info?.como_soy) {
    //   response.info.data[0].info.como_soy = JSON.parse(
    //     response.info?.data[0]?.info?.como_soy
    //   );
    // }

    // if (response.info?.data[0]?.info?.detalles) {
    //   response.info.data[0].info.detalles = JSON.parse(
    //     response.info?.data[0]?.info?.detalles
    //   );
    // }
    // if (response.info?.data[0]?.info?.otros) {
    //   response.info.data[0].info.otros = JSON.parse(
    //     response.info?.data[0]?.info?.otros
    //   );
    // }
    return response;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
