import { useNavigate } from "react-router-dom";
import { HomeIcon } from "../../assets/icons/HomeIcon";
import { RefreshIcon } from "../../assets/icons/RefreshIcon";
import Logo from "./Logo";
import * as Sentry from "@sentry/react";

const ErrorFallback: Sentry.FallbackRender = ({ error, resetError }) => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex items-center justify-center mb-6">
          <Logo />
        </div>
        <h1 className="text-2xl font-bold text-center mb-4 text-gray-800">
          Oops! Algo ha salido mal
        </h1>
        <p className="text-center text-gray-600 mb-6">
          Lo sentimos por el inconveniente. Esto es lo que sabemos:
        </p>
        <div className="bg-gray-100 p-4 rounded-md mb-6">
          <p className="text-sm text-gray-700 break-words">
            {error && error instanceof Error
              ? error.message
              : "An unknown error occurred"}
          </p>
        </div>
        <div className="flex flex-col space-y-3">
          <button
            onClick={() => navigate("/")}
            className="flex items-center justify-center px-4 py-2 bg-[#A24BFD] text-white rounded-md hover:bg-[#8A3FD9] transition-colors duration-300"
          >
            <HomeIcon className="mr-2" />
            Volver al inicio
          </button>
          <button
            onClick={resetError}
            className="flex items-center justify-center px-4 py-2 border border-[#A24BFD] text-[#A24BFD] rounded-md hover:bg-[#A24BFD] hover:text-white transition-colors duration-300"
          >
            <RefreshIcon className="mr-2" />
            Volver a intentar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
