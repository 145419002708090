import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function saveUserVerificationPath(data: any) {
  try {
    let response = (await axiosClient.post(Api.verify.addPhoto, data, {})).data;
    if (response.info?.data[0]?.profile) {
      //Why i need to do that? ask the back guy
      response.info.data.user = response.info?.data[0];
      response.info.data.user.info = response.info?.data[0]?.profile;
      response.info.data.user.perfil_id = response.info?.data[0]?.profile.id;
    }
    if (response.info?.data?.user?.info?.como_soy) {
      response.info.data.user.info.como_soy = JSON.parse(
        response.info?.data?.user?.info?.como_soy
      );
    }
    if (response.info?.data?.user?.info?.detalles) {
      response.info.data.user.info.detalles = JSON.parse(
        response.info?.data?.user?.info?.detalles
      );
    }
    if (response.info?.data?.user?.info?.otros) {
      response.info.data.user.info.otros = JSON.parse(
        response.info?.data?.user?.info?.otros
      );
    }
    return response;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
