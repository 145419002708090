import { Button, Modal, ModalContent } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
// import { ShareSocial } from "react-share-social";
import { useAuthStore } from "../../stores/authStore";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { useState } from "react";

export default function ShareModal({
  isOpen = false,
  closeModal = () => {},
}: {
  isOpen: boolean;
  closeModal?: () => void;
}) {
  const [isCopied, setIsCopied] = useState(false);
  const onclose = () => {
    setIsCopied(false);
    closeModal();
  };
  //referido
  const authData = useAuthStore((state) => state.user);
  const url = () =>
    `${window.location.origin}/register?code=${authData?.referido}`;
  const shareUrl = url();
  const title = "Únete a Loouth";
  const copyUrl = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setIsCopied(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      className=""
      isOpen={isOpen}
      placement="center"
      onClose={onclose}
      classNames={{
        closeButton: "mt-4 mr-4",
        base: "rounded-xl border-none",
      }}
      closeButton={<CloseIcon />}
      scrollBehavior="inside"
      size="xl"
    >
      <ModalContent>
        <div className="">
          <div className="flex ">
            <div className="text-start w-full">
              <h3 className="mt-6 font-semibold text-color3 text-xl  px-6">
                Invita a un amigo
              </h3>
              <p className="mt-2 font-normal text-[14px] text-color5"></p>
              <div className="p-6 flex gap-2">
                <FacebookShareButton
                  url={shareUrl}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <FacebookMessengerShareButton
                  url={shareUrl}
                  appId="521270401588372"
                  className="Demo__some-network__share-button"
                >
                  <FacebookMessengerIcon size={32} round />
                </FacebookMessengerShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  title={title}
                  className="Demo__some-network__share-button"
                >
                  <XIcon size={32} round />
                </TwitterShareButton>
                <TelegramShareButton
                  url={shareUrl}
                  title={title}
                  className="Demo__some-network__share-button"
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                <WhatsappShareButton
                  url={shareUrl}
                  title={title}
                  separator=":: "
                  className="Demo__some-network__share-button"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <EmailShareButton
                  url={shareUrl}
                  subject={title}
                  body="body"
                  className="Demo__some-network__share-button"
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
                <InstapaperShareButton
                  url={shareUrl}
                  title={title}
                  className="Demo__some-network__share-button"
                >
                  <InstapaperIcon size={32} round />
                </InstapaperShareButton>
              </div>
              <div className="p-6">
                <div className="w-full h-12 rounded-xl border border-primary flex justify-between p-4 pr-1 items-center">
                  <span className="text-color2">{shareUrl}</span>
                  <Button
                    onClick={copyUrl}
                    color="primary"
                    variant="light"
                    className="font-semibold"
                  >
                    {isCopied ? "Copiado" : "Copiar"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-6 pt-0 md:flex block justify-end ">
            <div className="md:mt-0 mt-4 flex gap-[12px]">
              <SecondaryButton className="md:w-auto w-full" onClick={onclose}>
                Cancelar
              </SecondaryButton>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
