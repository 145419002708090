import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function createParty(data: any) {
  try {
    return (await axiosClient.post(Api.party.index, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
const addFilters = (data: any) => {
  let query = "";
  let count = 0;
  if (data.nombre) {
    query += `?nombre=${data.nombre}`;
    count++;
  }
  if (data.fecha_inicio) {
    query += `${count > 0 ? "&" : "?"}fecha_inicio=${data.fecha_inicio}`;
    count++;
  }
  if (data.fecha_fin) {
    query += `${count > 0 ? "&" : "?"}fecha_fin=${data.fecha_fin}`;
    count++;
  }
  if (data.orderColumn) {
    query += `${count > 0 ? "&" : "?"}orderColumn=${
      data.orderColumn
    }&order=ASC`;
    count++;
  }
  if (data.page) {
    query += `${count > 0 ? "&" : "?"}page=${data.page}`;
    count++;
  }
  if (data.fantasias_id) {
    query += `${count > 0 ? "&" : "?"}fantasias_id=${data.fantasias_id}`;
    count++;
  }
  if (data.tipo_id) {
    query += `${count > 0 ? "&" : "?"}tipo_id=${data.tipo_id}`;
    count++;
  }
  if (data.estado != undefined) {
    query += `${count > 0 ? "&" : "?"}estado=${data.estado}`;
    count++;
  }
  if (data.user_id && !data.attended) {
    query += `${count > 0 ? "&" : "?"}user_id=${data.user_id}`;
  }
  return query;
};

export async function getParties(data: any) {
  try {
    const query = addFilters(data);
    if (data.attended && data.user_id)
      return (
        await axiosClient.get(`${Api.party.index}/user/${data.user_id}${query}`)
      ).data;

    return (await axiosClient.get(`${Api.party.index}${query}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function getPartyTypes() {
  try {
    return (await axiosClient.get(`${Api.party.partyType}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function getParty(id: number) {
  try {
    const response = (await axiosClient.get(`${Api.party.index}/${id}`)).data;
    return response;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function requestJoinParty(data: any) {
  try {
    return (await axiosClient.post(`${Api.party.request}`, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function respondRequestJoinParty(data: any, id: number) {
  try {
    return (await axiosClient.post(`${Api.party.request}/${id}`, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function editParty(data: any) {
  try {
    return (await axiosClient.put(`${Api.party.index}/${data.id}`, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function deleteParty(id: number) {
  try {
    return (await axiosClient.delete(`${Api.party.index}/${id}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function likeParty(id: number) {
  try {
    return (await axiosClient.post(`${Api.party.index}/${id}/like`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
