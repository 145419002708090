import { Navigate } from "react-router";
import { useAuthStore } from "../../stores/authStore";
import { useStepperStore } from "../../stores/stepperStore";

const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const authData = useAuthStore((state) => state.user);
  const setStep = useStepperStore((state) => state.setStep);

  const verify = () => {
    // console.log(authData);
    if (
      !authData ||
      (authData && authData.email_verified_at == null) ||
      (authData &&
        authData.email_verified_at &&
        !authData?.verified &&
        !authData?.info?.verification_path &&
        authData.role_id == "2")
    ) {
      setStep(0);
      return <Navigate to="/login" replace />;
    } else if (!authData.info) {
      if (authData.role_id == "2") setStep(1);
      else setStep(2);
      return <Navigate to="/register" replace />;
    } else return element;
  };
  return verify();
};

export default PrivateRoute;
