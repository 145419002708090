import { Modal, ModalContent } from "@nextui-org/react";
import CodeInput from "../form/CodeInput";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { resendCode, resetPassword, verifyCode } from "../../api/auth";
import { User } from "../../interfaces/Login";
import { HomeData } from "../../interfaces/homeData";
import { useLoginStore } from "../../stores/loginDataStore";
import { useAuthStore } from "../../stores/authStore";
import { useHomeStore } from "../../stores/homeData";
import { CloseIcon } from "../../assets/icons/CloseIcon";

export default function VerifyEmail({
  email = "",
  isOpen = false,
  closeModal = () => {},
  next = () => {},
  isForResetPass = false,
}: {
  email?: string;
  isOpen: boolean;
  closeModal?: () => void;
  token?: string;
  next?: () => void;
  isForResetPass?: boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const setUser = useLoginStore((state) => state.saveUser);
  const saveToken = useAuthStore((state) => state.saveToken);
  const setHome = useHomeStore((state) => state.setHome);
  const resend = async () => {
    try {
      const responseData = await resendCode();

      // console.log(responseData);
      if (responseData.error == "true") {
        toast.error(responseData.info?.message);
      } else {
        toast.success(responseData.info.message);
      }
    } catch (error) {
      toast.error("Error desconocido");
    }
  };

  async function verifyAccount(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);

    const data = { code: formData.get("code") as string, email };

    if (!data.code || data.code.length < 4) {
      toast.error("Introduzca el código de verificación completo");

      return;
    }
    setIsLoading(true);
    let response = null;
    try {
      const responseData = await verifyCode(data);
      response = responseData;
      if (responseData.error == "true" || !responseData.info) {
        toast.error(responseData.info.message);
      } else {
        toast.success(responseData.info?.message);
        setUser(responseData.info.data.user as User);

        if (responseData.info.data.home?.home) {
          setHome(responseData.info.data.home.home as HomeData);
          //console.log(responseData.info.data.home.home);
        }
        //saveToken(responseData.info.data.token);
        next();
        closeModal();
      }
    } catch (error) {
      toast.success(response.info?.message ?? "Error desconocido");
    }
    setIsLoading(false);
  }
  async function verifyEmailToRecover(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);

    const data = { code: formData.get("code") as string, email };

    if (!data.code || data.code.length < 4) {
      toast.error("Introduzca el código de verificación completo");

      return;
    }
    setIsLoading(true);
    let response = null;
    try {
      const responseData = await resetPassword(data);
      response = responseData;
      if (responseData.error == "true" || !responseData.info) {
        toast.error(responseData.info.message);
      } else {
        toast.success(responseData.info?.message);
        setUser(responseData.info.data.user as User);
        saveToken(responseData.info.data.token);
        if (responseData.info.data.home?.home) {
          setHome(responseData.info.data.home.home as HomeData);
        }
        next();
        closeModal();
      }
    } catch (error) {
      toast.success(response.info?.message ?? "Error desconocido");
    }
    setIsLoading(false);
  }
  return (
    <Modal
      className="md:max-w-[408px] max-w-[343px]"
      isOpen={isOpen}
      placement="center"
      onClose={closeModal}
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <form
          onSubmit={isForResetPass ? verifyEmailToRecover : verifyAccount}
          className="p-7"
        >
          <div className="flex justify-center">
            <div className="text-center">
              <div className="mt-1 flex justify-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
                    stroke="#A24BFD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h3 className="mt-6 font-semibold text-color3 text-[18px]">
                Verifica tu correo electrónico
              </h3>
              <p className="mt-1 font-normal text-[14px] text-color5">
                Te hemos enviado un código al correo
              </p>
              <p className="font-bold text-[14px] text-color5">{email}</p>
            </div>
          </div>
          <div className="mt-5 flex justify-center">
            <div>
              <div className="flex gap-3">
                <CodeInput />
              </div>
              <p className="mt-1 text-[14px] font-normal text-color5">
                ¿No has recibido el código?{" "}
                <span
                  onClick={resend}
                  className="underline underline-offset-2 cursor-pointer"
                >
                  Volver a enviar
                </span>
              </p>
            </div>
          </div>
          <div className="mt-8 flex justify-center gap-[12px]">
            <SecondaryButton className="w-full" onClick={closeModal}>
              Cancelar
            </SecondaryButton>
            <PrimaryButton type="submit" className="w-full" loading={isLoading}>
              Verificar
            </PrimaryButton>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
}
