import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PrimaryButton from "../components/common/PrimaryButton";
import PlaneIcon from "../assets/icons/PlaneIcon";
import TripList from "../components/trip/TripList";
import { useEffect, useState } from "react";
import CreateTripModal from "../components/trip/CreateTripModal";
import { Filters, Trip } from "../interfaces/Trip";
import { useAuthStore } from "../stores/authStore";
import { getTrips } from "../api/trip";
import { DateValue, RangeValue } from "@nextui-org/react";
import { parseDate } from "@internationalized/date";
import { Pagination } from "../interfaces/pagination";
import { withErrorHandler } from "../utils/withErrorFallback";
import { SexualOrientation } from "../interfaces/SexualOrientation";
import { getProvinces, getSexualOrientations } from "../api/formSelectsData";
import { Province } from "../interfaces/Provice";

export default function Trips() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({
    trips: [] as Trip[],
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const authData = useAuthStore((state) => state.user);
  const [orientations, setOrientations] = useState<SexualOrientation[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);

  const [date, setDate] = useState<RangeValue<DateValue>>({
    start: parseDate(new Date().toISOString().split("T")[0]),
    end: parseDate(
      new Date(new Date().setMonth(new Date().getMonth() + 4))
        .toISOString()
        .split("T")[0]
    ),
  });
  const [filters, setFilters] = useState<Filters>({
    fantasias_id: new Set<string>([]),
    tipo_viaje: new Set<string>([]),
    pais: new Set<string>([]),
    user_id: undefined,
  });
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });
  useEffect(() => {
    const getExtra = async () => {
      const orientations = await getSexualOrientations();
      const provinces = await getProvinces();

      setOrientations(orientations?.info?.data[0] ?? []);
      setProvinces(provinces.info.data[0]);
    };
    getExtra();
  }, []);

  useEffect(() => {
    // console.log(authData?.info?.fantasias_id);
    const getData = withErrorHandler(async () => {
      setIsLoading(true);

      const filtersData = {
        nombre: "",
        fecha_inicio: date.start,
        fecha_fin: date.end,
        fantasias_id:
          Array.from(filters.fantasias_id).length > 0
            ? Array.from(filters.fantasias_id).join(",")
            : undefined,
        tipo_viaje:
          Array.from(filters.tipo_viaje).length > 0
            ? Array.from(filters.tipo_viaje).join(",")
            : undefined,
        pais:
          Array.from(filters.pais).length > 0
            ? Array.from(filters.pais).join(",")
            : undefined,
      };

      const trips = await getTrips(filtersData);
      setData({
        ...data,
        trips: trips?.info?.data[0].data ?? [],
        total: trips?.info?.data[0].total ?? 0,
      });
      setPagination({
        current_page: trips?.info?.data[0].current_page,
        last_page: trips?.info?.data[0].last_page,
        total: trips?.info?.data[0].total,
        per_page: trips?.info?.data[0].per_page,
      });
      setIsLoading(false);
    });
    if (authData) getData();
  }, [date, filters]);

  const onSuccessCreateTrip = (trip: Trip) => {
    setData({
      ...data,
      trips: [trip, ...data.trips],
    });
  };
  const onPageChange = async (page: number, orderColumn?: string) => {
    setIsLoading(true);

    const filtersData = {
      nombre: "",
      fecha_inicio: date.start,
      fecha_fin: date.end,
      page: page,
      orderColumn: orderColumn,
      fantasias_id:
        Array.from(filters.fantasias_id).length > 0
          ? Array.from(filters.fantasias_id).join(",")
          : undefined,
      tipo_viaje:
        Array.from(filters.tipo_viaje).length > 0
          ? Array.from(filters.tipo_viaje).join(",")
          : undefined,
      pais:
        Array.from(filters.pais).length > 0
          ? Array.from(filters.pais).join(",")
          : undefined,
    };

    const trips = await getTrips(filtersData);
    console.log("trips", trips);
    setData({
      ...data,
      trips: trips?.info?.data[0].data ?? [],
      total: trips?.info?.data[0].total ?? 0,
    });
    setPagination({
      current_page: trips?.info?.data[0].current_page,
      last_page: trips?.info?.data[0].last_page,
      total: trips?.info?.data[0].total,
      per_page: trips?.info?.data[0].per_page,
    });

    setIsLoading(false);
  };
  return (
    <>
      <NavBar />
      <div className="">
        <main className="flex items-center  w-full overflow-hidden pt-20 md:pt-8 min-h-[100vh] flex-col">
          <div className="px-4 md:px-14 w-full max-w-8xl">
            <section className="w-full flex flex-wrap items-center justify-center">
              <div className="mt-2 flex flex-col items-start w-full md:w-10/12">
                <h1 className="text-2xl font-semibold">Viajes</h1>
                <p>
                  Aquí encontrarás los próximos viajes que tienen planeados los
                  usuarios Loouth. Publica tus viajes y vive experiencias únicas
                  allá donde vayas.
                </p>
              </div>

              <div className="mt-2 flex justify-end w-full sm:w-3/4 md:w-2/12 md:pl-3">
                {authData?.role_id != "3" && (
                  <PrimaryButton
                    className="w-full"
                    onClick={() => setShowModal(true)}
                  >
                    <div className="flex items-center gap-2">
                      <PlaneIcon height={20} width={20} stroke={"white"} />
                      <span className="inline md:hidden lg:inline">
                        Crear viaje
                      </span>
                    </div>
                  </PrimaryButton>
                )}
              </div>
            </section>
          </div>
          <TripList
            isLoading={isLoading}
            trips={data.trips}
            total={data.total}
            dateRange={date}
            onChangeDate={setDate}
            pagination={pagination}
            onPageChange={onPageChange}
            filters={filters}
            setFilters={setFilters}
            provinces={provinces}
            orientations={orientations}
          />
        </main>
        <Footer />
      </div>
      <CreateTripModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        onSuccessCreateTrip={onSuccessCreateTrip}
      />
    </>
  );
}
