import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { Card, Pagination, Skeleton } from "@nextui-org/react";

import { useEffect, useState } from "react";
import { BlockedData } from "../interfaces/Login";
import { Pagination as PaginationData } from "../interfaces/pagination";
import { getBlockedUsers } from "../api/friends";
import BloquedUserCard from "../components/profile/BlockedUserCard";
import { withErrorHandler } from "../utils/withErrorFallback";

export default function BlockedUsers() {
  const [isLoading, setIsLoading] = useState(false);

  const [blocked, setBlocked] = useState<BlockedData[]>([]);

  const [pagination, setPagination] = useState<PaginationData>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });

  const getData = withErrorHandler(async (page: number) => {
    setIsLoading(true);

    const res = await getBlockedUsers(page);
    // console.log("blocked", res);
    if (res?.info?.data[0]?.data) {
      setBlocked(res?.info?.data[0]?.data);
    }
    setPagination({
      current_page: res?.info?.data[0].current_page,
      last_page: res?.info?.data[0].last_page,
      total: res?.info?.data[0].total,
      per_page: res?.info?.data[0].per_page,
    });
    setIsLoading(false);
  });

  const onPageChange = async (page: number) => {
    getData(page);
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <div className=" h-screen ">
      <NavBar />
      <main className="flex justify-center">
        <div className="flex max-w-8xl w-full overflow-hidden pt-6 md:pt-18 min-h-[100vh] flex-col md:px-6">
          <div className="px-4 text-2xl w-full max-w-8xl mb-4">
            <section className="w-full mb-4">
              <div className="mt-2 flex flex-col items-start">
                <h1 className=" font-semibold">Usuarios bloqueados</h1>
              </div>
            </section>
          </div>
          <div className="flex w-full h-full">
            <div className="flex-grow flex flex-col justify-between w-full">
              <div className="w-full  pl-4 xl:pl-6">
                <section className="w-full flex">
                  <div className=" flex flex-col w-full">
                    <div className="w-full mb-2 flex  justify-between "></div>
                    <div className="w-full xl:overflow-auto xl:max-h-[calc(100vh-220px)] xl:pb-2 pt-4 pr-2">
                      <div className="w-full gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
                        {isLoading ? (
                          [0, 0, 0, 0].map((_, i) => (
                            <Card
                              className="w-full space-y-5 p-4 shadow-none border-1 border-gray-100"
                              radius="lg"
                              key={i}
                            >
                              <Skeleton className="rounded-lg">
                                <div className="h-24 rounded-lg bg-default-300"></div>
                              </Skeleton>
                              <div className="space-y-3">
                                <Skeleton className="w-3/5 rounded-lg">
                                  <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                                </Skeleton>
                                <Skeleton className="w-4/5 rounded-lg">
                                  <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                                </Skeleton>
                                <Skeleton className="w-2/5 rounded-lg">
                                  <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                                </Skeleton>
                              </div>
                            </Card>
                          ))
                        ) : blocked.length == 0 ? (
                          <p>No hay usuarios para mostrar</p>
                        ) : (
                          blocked.map((it) => (
                            <BloquedUserCard
                              user={it}
                              onSuccessDelete={(id: number) => {
                                setBlocked([
                                  ...blocked.filter((e) => e.id != id),
                                ]);
                              }}
                            />
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {pagination && pagination?.current_page > 0 && (
                <div className="px-4 mt-3 mb-2 w-full">
                  <div className="flex justify-center">
                    <Pagination
                      showControls
                      total={pagination.last_page}
                      initialPage={1}
                      onChange={onPageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
