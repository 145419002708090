import { Link } from "react-router-dom";
import Logo from "./Logo";

export default function Footer() {
  return (
    <footer className="mt-12 w-full border-t-1 px-6">
      <div className="flex flex-col justify-center items-center">
        <div className="mt-16 md:mt-24 max-w-28 md:w-auto">
          <Logo />
        </div>
        <ul className="mt-10 font-semibold w-full text-color5 justify-center flex flex-wrap gap-5 sm:gap-10 sm:flex-row">
          <li>
            <Link to="https://loouth.com/politica-de-privacidad">
              Política de Privacidad
            </Link>
          </li>
          <li>
            <Link to="https://loouth.com/politica-de-cookies">
              Política de Cookies
            </Link>
          </li>
          <li>
            <Link to="https://loouth.com/aviso-legal">Aviso Legal</Link>
          </li>
          <li>
            <Link to="https://loouth.com/terminos-y-condiciones">
              Términos & Condiciones
            </Link>
          </li>
          <li>Ayuda</li>
          <li>Sobre Nosotros</li>
        </ul>
        <p className="mt-16 mb-14 font-normal text-[16px] text-color1">
          Disfruta y déjate llevar de manera segura y responsable
        </p>
      </div>
    </footer>
  );
}
