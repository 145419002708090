import { useMemo } from "react";
import { useAuthStore } from "../../stores/authStore";
import { Link } from "react-router-dom";
import { Avatar } from "@nextui-org/react";

export default function MyAbout({
  visits = 0,
  friends = 0,
  likes = 0,
}: {
  visits: number;
  friends: number;
  likes: number;
}) {
  const authData = useAuthStore((state) => state.user);

  const cover = useMemo(() => {
    return authData?.portada_path
      ? authData?.portada_path.startsWith("http")
        ? authData?.portada_path
        : import.meta.env.VITE_BASE_URL + authData?.portada_path
      : undefined;
  }, [authData]);

  return (
    <section className="w-full border-1 rounded-2xl">
      <div>
        <div
          className="w-full h-[75px] rounded-t-2xl bg-gradient-default  bg-center bg-cover"
          style={{
            backgroundImage: ` url(${cover})`,
          }}
        ></div>

        <div className="-mt-9 w-full flex justify-center">
          <Link to={`/profile/${authData?.id}`}>
            <Avatar
              showFallback
              classNames={{
                base: "bg-zinc-100 border-3 border-white",
                icon: "text-black/50",
              }}
              className={`w-[68px] h-[68px]  ${
                authData?.is_payed ? "border-3 border-primary" : ""
              }`}
              src={
                authData?.profile_path
                  ? authData?.profile_path.startsWith("http")
                    ? authData?.profile_path
                    : import.meta.env.VITE_BASE_URL + authData?.profile_path
                  : undefined
              }
            />
          </Link>
        </div>
        <div className="mt-1 flex flex-col items-center">
          <Link to={`/profile/${authData?.id}`}>
            <label className="font-bold text-[20px] text-color2 cursor-pointer hover:text-color4 transition-transform-colors-opacity">
              {authData?.name}
            </label>
          </Link>
          {authData?.is_payed ? (
            <p className="text-tiny font-semibold text-primary tracking-[4px] uppercase space-x-2">
              Premium
            </p>
          ) : (
            <></>
          )}
        </div>
        <ul className="p-6 pl-7 flex flex-col gap-2">
          <li className="flex items-center gap-3">
            <div>
              <svg
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="37" height="37" rx="18.5" fill="#FF01EE" />
                <path
                  d="M24.1777 14C29.2737 14 29.2737 22 24.1777 22C19.0827 22 17.0447 14 11.4388 14C6.85375 14 6.85375 22 11.4388 22C17.0447 22 19.0828 14 24.1788 14H24.1777Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="flex flex-col">
              <label className="font-semibold text-[16px] text-color7">
                Visitas al Perfil
              </label>
              <label className="font-medium text-[16px] text-color5">
                {visits}
              </label>
            </div>
          </li>
          <li className="flex items-center gap-3">
            <div>
              <svg
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="37" height="37" rx="18.5" fill="#0038E5" />
                <path
                  d="M24.75 20.8573C26.0239 21.4972 27.1161 22.5242 27.9133 23.8084C28.0712 24.0627 28.1501 24.1899 28.1774 24.366C28.2329 24.7238 27.9882 25.1637 27.6549 25.3053C27.4909 25.375 27.3064 25.375 26.9375 25.375M23 17.0907C24.2965 16.4464 25.1875 15.1085 25.1875 13.5625C25.1875 12.0165 24.2965 10.6786 23 10.0343M21.25 13.5625C21.25 15.7371 19.4871 17.5 17.3125 17.5C15.1379 17.5 13.375 15.7371 13.375 13.5625C13.375 11.3879 15.1379 9.625 17.3125 9.625C19.4871 9.625 21.25 11.3879 21.25 13.5625ZM11.2393 23.571C12.6343 21.4765 14.8357 20.125 17.3125 20.125C19.7893 20.125 21.9906 21.4765 23.3857 23.571C23.6913 24.0299 23.8441 24.2593 23.8265 24.5524C23.8128 24.7806 23.6632 25.06 23.4808 25.1979C23.2467 25.375 22.9246 25.375 22.2804 25.375H12.3445C11.7004 25.375 11.3783 25.375 11.1441 25.1979C10.9618 25.06 10.8122 24.7806 10.7985 24.5524C10.7809 24.2593 10.9337 24.0299 11.2393 23.571Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="flex flex-col">
              <label className="font-semibold text-[16px] text-color7">
                Amigos
              </label>
              <label className="font-medium text-[16px] text-color5">
                {friends}
              </label>
            </div>
          </li>
          <li className="flex items-center gap-3">
            <div>
              <svg
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="37" height="37" rx="18.5" fill="#00C800" />
                <path
                  d="M22.5455 16.9254C22.9195 16.261 23.2313 15.6615 23.4236 15.2052C24.3573 12.9895 23.434 10.4408 21.1769 9.40112C18.9199 8.36148 16.6534 9.4395 15.6587 11.5209C13.7566 10.2157 11.2192 10.4074 9.81989 12.4442C8.42059 14.4811 8.85975 17.142 10.7763 18.594C11.6461 19.253 13.3664 20.2242 14.986 21.0884M23.2972 18.7499C22.8751 16.482 20.9454 14.8233 18.5156 15.2742C16.0859 15.725 14.5149 17.9171 14.8433 20.299C15.1072 22.2127 16.5639 26.7027 17.1264 28.394C17.2032 28.6248 17.2415 28.7402 17.3175 28.8206C17.3837 28.8907 17.4717 28.9416 17.5655 28.9638C17.6732 28.9894 17.7923 28.9649 18.0306 28.916C19.7765 28.5575 24.3933 27.574 26.1826 26.8457C28.4096 25.9392 29.5589 23.4841 28.6981 21.153C27.8372 18.8219 25.4723 17.9815 23.2972 18.7499Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="flex flex-col">
              <label className="font-semibold text-[16px] text-color7">
                Me gusta
              </label>
              <label className="font-medium text-[16px] text-color5">
                {likes}
              </label>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}
