/**  */

import { Button } from "@nextui-org/react";

export default function LoginImage() {
  return (
    <div
      className={` bg-[url('/assets/login.jpeg')] h-full w-full bg-center bg-cover rounded-3xl opacity-100 `}
    >
      <div className="absolute bottom-0 w-full h-72 backdrop-blur-md bg-gray-400/30 rounded-b-3xl border-t-1 border-gray-400"></div>
      <div className="absolute bottom-0 w-full h-72 p-6 text-white pt-8">
        <p className="text-2xl font-semibold ">
          “La primera red social swinger donde realmente conecto con las
          personas que me interesan”
        </p>
        <div className="flex w-full justify-between mt-12">
          <div>
            <p className="text-2xl">P. Orozco</p>
            <p className="text-lg">Usuario de Loouth</p>
            <p className="text-sm">Alicante</p>
          </div>
          <div>
            <div className="flex gap-1">
              {[0, 0, 0, 0, 0].map((_, i) => (
                <svg
                  key={i}
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.53834 0.609964C8.70914 0.199318 9.29086 0.199318 9.46166 0.609964L11.5278 5.57744C11.5998 5.75056 11.7626 5.86885 11.9495 5.88383L17.3123 6.31376C17.7556 6.3493 17.9354 6.90256 17.5976 7.19189L13.5117 10.6919C13.3693 10.8139 13.3071 11.0053 13.3506 11.1876L14.5989 16.4208C14.7021 16.8534 14.2315 17.1954 13.8519 16.9635L9.26063 14.1592C9.10062 14.0615 8.89938 14.0615 8.73937 14.1592L4.14806 16.9635C3.76851 17.1954 3.29788 16.8534 3.40108 16.4208L4.64939 11.1876C4.69289 11.0053 4.6307 10.8139 4.48831 10.6919L0.402413 7.19189C0.0646446 6.90256 0.244408 6.3493 0.687735 6.31376L6.05054 5.88383C6.23744 5.86885 6.40024 5.75056 6.47225 5.57744L8.53834 0.609964Z"
                    fill="white"
                  />
                </svg>
              ))}
            </div>
            <div className="flex gap-6 pt-5">
              <Button
                radius="full"
                isIconOnly
                aria-label="previous"
                variant="bordered"
                className="border-1"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 8H1M1 8L8 15M1 8L8 1"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
              <Button
                radius="full"
                isIconOnly
                aria-label="next"
                variant="bordered"
                className="border-1"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 8H15M15 8L8 1M15 8L8 15"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
