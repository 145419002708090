import { Modal, ModalContent, Progress } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import PrimaryButton from "../common/PrimaryButton";
import VerifyImageExample from "../../assets/verify-image.png";
import FileInput from "../form/FileInput";
import { FormEvent, useEffect, useRef, useState } from "react";
import { round } from "../../utils/useNumber";
import { saveUserVerificationPath } from "../../api/verify";
import { useAuthStore } from "../../stores/authStore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../stores/loginDataStore";
import { uploadFile } from "../../api/upload";
import { AxiosProgressEvent } from "axios";
import { CloseIcon } from "../../assets/icons/CloseIcon";
// import { uploadFile } from "../api/upload";
// import { AxiosProgressEvent } from "axios";

export default function PersonVerify({
  isOpen = false,
  closeModal = () => {},
}: {
  isOpen: boolean;
  closeModal?: () => void;
}) {
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState("");
  const [code, setCode] = useState("");
  const inputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const user = useAuthStore((state) => state.user);
  // const [error, setError] = useState("");
  // const [percentCompleted, setPercentCompleted] = useState(0);

  const saveUser = useAuthStore((state) => state.saveUser);
  const setUser = useLoginStore((state) => state.saveUser);
  const [percentCompleted, setPercentCompleted] = useState(0);

  const navigate = useNavigate();

  const verifyAccount = async () => {
    if (!user || !file) return;
    const formData = new FormData();
    if (file) {
      formData.append("files[0]", file, file.name);
      formData.append("type", "image");
      formData.append("folder[0]", "verify");
    }
    setPercentCompleted(0);
    setIsLoading(true);
    const uploadResponse = await uploadFile(formData, {
      onUploadProgress: (progressEvent: AxiosProgressEvent) =>
        setPercentCompleted(
          Math.round((progressEvent.loaded * 100) / progressEvent.total!)
        ),
    });

    if (uploadResponse?.error == "true" || !uploadResponse.info) {
      toast.error(uploadResponse?.info?.message ?? "Errordesconocido");
      setIsLoading(false);
      setPercentCompleted(0);
      return;
    }

    //setIsLoading(true);
    const data = {
      user_id: user.id,
      verification_path: uploadResponse.info.data[0].verify.file.content,
      code_photo: code,
    };
    // data.append("user_id", user.id.toString());
    // data.append("verification_path", file, file.name);

    const response = await saveUserVerificationPath(data);
    if (response?.error == "true" || !response.info) {
      toast.error(response?.info?.message ?? "Error desconocido");

      setIsLoading(false);
    } else {
      toast.success(response.info.message);
      saveUser(response.info.data[0]);
      setUser(response.info.data[0]);
      navigate("/");
    }
    setPercentCompleted(0);
    setIsLoading(false);
  };

  const onFileUpload = () => {
    if (inputRef.current) (inputRef.current as HTMLInputElement).click();
  };
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    if (
      e.nativeEvent.target instanceof HTMLInputElement &&
      e.nativeEvent.target.files
    ) {
      // Aquí puedes procesar los archivos seleccionados
      const selectedFiles = Array.from(e.nativeEvent.target.files);
      // console.log(selectedFiles);
      if (selectedFiles.length > 0) {
        setFile(selectedFiles[0]);
      }
      // console.log(file);
    }
  };
  useEffect(() => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      // console.log(fileUrl);

      setFileUrl(fileUrl);

      return () => {
        URL.revokeObjectURL(fileUrl);
      };
    }
  }, [file]);
  const onCancel = () => {
    saveUser(null);
    setUser(null);
    closeModal();
  };
  useEffect(() => {
    setCode(Math.floor(Math.random() * (9999 - 1100) + 1100).toString());
  }, []);

  return (
    <Modal
      className="lg:max-w-[920px] Xmax-w-[343px] overflow-auto max-h-[600px]  h-full sm:h-auto flex flex-row"
      isOpen={isOpen}
      placement="center"
      onClose={closeModal}
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <div className="p-4 lg:p-7 w-full flex  h-full ">
          <div className="">
            <div className="flex justify-start">
              <p className="font-semibold text-[18px] text-color4">
                A continuación sube una foto con tu cara y enseñando un papel
                donde debes escribir el código que se muestra debajo
              </p>
            </div>
            <p className="text-primary font-bold text-2xl mt-3">{code}</p>

            <div className="mt-5 flex flex-col items-center lg:items-stretch justify-center ">
              <div className="text-start">
                <p className="font-normal text-[14px] text-color5">
                  Todos los usuarios de Loouth deben de ser perfiles reales para
                  garantizar la seguridad de nuestra comunidad.
                </p>
              </div>
              <div className="mt-6 lg:hidden flex h-full w-full m-auto justify-center">
                <img src={VerifyImageExample} className="w-[354px]" />
              </div>
              <FileInput onClick={onFileUpload} file={fileUrl} />
              <input
                ref={inputRef}
                className="hidden"
                type="file"
                accept="image/*"
                onChange={handleChange}
              ></input>
              {file && (
                <div className="flex mt-5 w-full lg:w-[418px] h-[96px] p-3">
                  <div className="ml-4 w-full">
                    <div className="flex justify-between w-full">
                      <label className="font-medium text-[14px] text-color2">
                        {file.name}
                      </label>
                      <div className="mr-2">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="15"
                            height="15"
                            rx="7.5"
                            fill="#A24BFD"
                          />
                          <rect
                            x="0.5"
                            y="0.5"
                            width="15"
                            height="15"
                            rx="7.5"
                            stroke="#A24BFD"
                          />
                          <path
                            d="M11.3332 5.5L6.74984 10.0833L4.6665 8"
                            stroke="white"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="-mt-1">
                      <label className="font-normal text-[14px] text-color5">
                        {round(file.size / 1024)}KB
                      </label>
                    </div>
                    <div className="xflex mt-1 items-center gap-3 hidden">
                      <Progress
                        size="md"
                        aria-label="Cargando..."
                        value={100}
                        className="max-w-[285px] h-[8px]"
                        classNames={{
                          indicator: "bg-color4",
                        }}
                      />
                      <label className="font-medium text-[14px] mb-0.5 ">
                        100%
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {isLoading && (
                <div className="flex items-center gap-3 mt-2 w-full">
                  <Progress
                    size="md"
                    aria-label="Cargando..."
                    value={percentCompleted}
                    className=" h-[8px]"
                    classNames={{
                      indicator: "bg-color4",
                    }}
                  />
                  <label className="font-medium text-[14px] mb-0.5 ">
                    {percentCompleted}%
                  </label>
                </div>
              )}
            </div>
            <div className="mt-8 md:flex justify-center gap-[12px]">
              <SecondaryButton className="w-full" onClick={onCancel}>
                Cancelar
              </SecondaryButton>
              <PrimaryButton
                className="w-full md:mt-0 mt-3"
                onClick={verifyAccount}
                loading={isLoading}
                disabled={!file}
              >
                Solicitar Verificación
              </PrimaryButton>
            </div>
          </div>
          <div className="lg:flex hidden h-full w-full m-auto justify-center">
            <img src={VerifyImageExample} className="w-[354px]" />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
