import { Avatar } from "@nextui-org/react";
import { User } from "../../interfaces/Login";
import { Link } from "react-router-dom";
// import { useDateFormatter } from "@react-aria/i18n";
import Moment from "react-moment";

export default function ChatBubble({
  isMine = false,
  content,
  user,
  dateString,
}: {
  isMine?: boolean;
  content: string;
  user: User;
  dateString: string;
}) {
  // let formatter = useDateFormatter({ dateStyle: "short", timeStyle: "short" });
  return (
    <>
      {isMine ? (
        <div className="pr-3 self-end my-2 flex gap-x-2.5">
          <div className="relative  max-w-80 rounded-lg bg-primary text-white text-start break-words">
            <p className="text-xs p-1 px-4 text-end text-gray-100">
              <Link to={`/profile/${user.id}`}>Tú</Link>
            </p>
            <div className="p-3 pt-1">{content}</div>
            <div className="absolute top-0 z-10 right-0">
              <div className="rounded-sm after:absolute after:top-1.5 after:-right-1 after:h-4 after:w-4 after:-rotate-45 after:transform after:after-r-2 after:after-t-2 after:after-primary after:bg-primary"></div>
            </div>
            <p className="text-xs p-1 px-2 text-white/60 text-end">
              <Moment fromNow locale="Es">
                {dateString}
              </Moment>
            </p>
          </div>
          <Link to={`/profile/${user.id}`}>
            <Avatar
              showFallback
              classNames={{
                base: "bg-zinc-100",
                icon: "text-black/50",
              }}
              src={
                user?.profile_path
                  ? user?.profile_path.startsWith("http")
                    ? user?.profile_path
                    : import.meta.env.VITE_BASE_URL + user?.profile_path
                  : undefined
              }
              className="cursor-pointer w-8 h-8"
            />
          </Link>
        </div>
      ) : (
        <div className="pl-3 my-2 flex gap-x-2.5">
          <Link to={`/profile/${user.id}`}>
            <Avatar
              showFallback
              classNames={{
                base: "bg-zinc-100",
                icon: "text-black/50",
              }}
              src={
                user?.profile_path
                  ? user?.profile_path.startsWith("http")
                    ? user?.profile_path
                    : import.meta.env.VITE_BASE_URL + user?.profile_path
                  : undefined
              }
              className="cursor-pointer w-8 h-8"
            />
          </Link>

          <div className="relative overflow-visible max-w-80 rounded-lg bg-blue-400 text-white text-start break-words">
            <Link
              to={`/profile/${user.id}`}
              className="text-gray-100 text-xs p-1 px-4 hover:text-white"
            >
              {user.name}
            </Link>
            <div className=" p-3 pt-1">{content}</div>
            <p className="text-xs p-1 px-2 text-white/60">
              <Moment fromNow locale="Es">
                {dateString}
              </Moment>
            </p>
            <div className="absolute top-0 left-0 z-10">
              <div className="rounded-sm before:absolute before:top-1.5 before:-left-1 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-l-2 before:border-t-2 before:border-blue-400 before:bg-blue-400">
                {" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
