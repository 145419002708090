import { Checkbox } from "@nextui-org/react";
import { FormEvent } from "react";

export default function CustomCheckBox({
  children = "Custom Checkbox",
  checked = false,
  onChange = () => {},
  isRequired = false,
}: {
  children?: React.ReactNode;
  checked?: boolean;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
}) {
  return (
    <Checkbox
      className=""
      classNames={{
        wrapper:
          "w-[16px] h-[16px] border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
        base: "font-medium text-[14px] text-color2",
      }}
      size="sm"
      checked={checked}
      onChange={onChange}
      required={isRequired}
    >
      {children}
    </Checkbox>
  );
}
