import * as Sentry from "@sentry/react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withErrorHandler<T extends (...args: any[]) => any>(fn: T): T {
  return ((...args: Parameters<T>): ReturnType<T> => {
    try {
      return fn(...args);
    } catch (error) {
      console.error("Caught error in event handler:", error);
      Sentry.captureException(error);
      throw error; // Re-throw the error so it can be caught by the error boundary
    }
  }) as T;
}
