import { SVGProps } from "react";

export function FlagIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.82em"
      height="1em"
      viewBox="0 0 13 16"
      {...props}
    >
      <path
        fill={props.fill ?? "#888888"}
        d="M11.36 10H1.5c-.28 0-.5-.22-.5-.5v-7c0-.28.22-.5.5-.5h9.86c.19 0 .42.14.51.31c.1.19.08.42-.04.59L9.62 5.99l2.21 3.09c.11.15.13.43.04.59a.57.57 0 0 1-.51.31ZM2 9h8.53l-1.9-2.67c-.12-.17-.12-.49 0-.67l1.9-2.67H2v6Z"
      ></path>
      <path
        fill={props.fill ?? "#888888"}
        d="M1.5 14c-.28 0-.5-.22-.5-.5V4.12c0-.28.22-.5.5-.5s.5.22.5.5v9.38c0 .28-.22.5-.5.5"
      ></path>
    </svg>
  );
}
