import { SVGProps } from "react";

export function LocationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00016 10.416C9.38087 10.416 10.5002 9.29673 10.5002 7.91602C10.5002 6.5353 9.38087 5.41602 8.00016 5.41602C6.61945 5.41602 5.50016 6.5353 5.50016 7.91602C5.50016 9.29673 6.61945 10.416 8.00016 10.416Z"
        stroke={props.stroke ?? "#344054"}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00016 18.3327C9.66683 14.9993 14.6668 12.8479 14.6668 8.33268C14.6668 4.65078 11.6821 1.66602 8.00016 1.66602C4.31826 1.66602 1.3335 4.65078 1.3335 8.33268C1.3335 12.8479 6.3335 14.9993 8.00016 18.3327Z"
        stroke={props.stroke ?? "#344054"}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
