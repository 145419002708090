import { Card, Modal, ModalContent, Skeleton } from "@nextui-org/react";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import PremiumIcon from "../../assets/icons/PremiumIcon";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { CheckIcon } from "../../assets/icons/CheckIcon";
import { useEffect, useState } from "react";
import { SubscriptionRequest } from "../../interfaces/Login";
// import { useAuthStore } from "../../stores/authStore";
import { toast } from "react-toastify";
import { createSubscription } from "../../api/mine";
import { getPlans } from "../../api/plans";
// import { Link } from "react-router-dom";

function PlanCard({
  title,
  price,
  properties,
  selected,
  id,
}: {
  id: string;
  title: string;
  price: string;
  properties: Array<string>;
  selected?: Plan;
}) {
  return (
    <article
      className={`p-4 w-[286px] h-[278px] rounded-xl ${
        selected?.id == id
          ? "border-2 border-color4"
          : " border-1 border-color1"
      }`}
    >
      <div className="flex justify-between">
        <label className="font-semibold text-[24px]">{price}</label>
        <div hidden={selected?.id != id}>
          <CheckIcon />
        </div>
      </div>
      <div className="mt-1.5">
        <h3 className="font-semibold text-[16px] text-color3">{title}</h3>
        <p className="font-normal text-[14px] text-color5">IVA Incluído</p>
      </div>
      <div className="mt-3">
        <ul className="font-normal text-[16px] text-color5">
          {properties.map((item) => (
            <li className="flex gap-3 py-1.5" key={item}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_367_4890)">
                  <path
                    d="M7.5 12.5L10.5 15.5L16.5 9.5M22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5Z"
                    stroke="#A24BFD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_367_4890">
                    <rect y="0.5" width="24" height="24" rx="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
}
interface Plan {
  id: string;
  name: string;
  price: number;
  price_id: string;
  periodo: string;
  active: string;
}
export default function PlanModal({
  isOpen = false,
  closeModal = () => {},
}: // finalizePurchase,
{
  isOpen: boolean;
  closeModal?: () => void;
  finalizePurchase: (plan: SubscriptionRequest) => void;
}) {
  // const authData = useAuthStore((state) => state.user);
  const [selected, setSelected] = useState<Plan | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [plans, setPlans] = useState<Plan[]>([]);

  useEffect(() => {
    const getData = async () => {
      setIsLoadingPlans(true);
      const data = await getPlans();
      if (data[0]) {
        const activePlans = (data[0] as Plan[]).filter((p) => p.active == "1");
        // console.log(activePlans);
        activePlans.sort((a: Plan, b: Plan) => a.price - b.price);
        setPlans(activePlans);
        setSelected(activePlans[0]);
        setIsLoadingPlans(false);
      }
    };
    if (isOpen) getData();
  }, [isOpen]);

  const makePayment = async () => {
    if (!selected) return;
    try {
      setIsLoading(true);

      const res = await createSubscription({
        price_id: selected.price_id,
      });
      setIsLoading(false);
      // console.log("responseeeeeeeee", res);
      if (res.error == "false") {
        const url = res.info.data["0"].payment_url;
        if (url) {
          toast.loading("Redireccionando...");
          window.location.href = url;
        }
      } else {
        toast.error("Ha ocurrido un error generando el enlace de pago");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      className="Xmd:max-w-[640px] max-w-[743px] w-max"
      isOpen={isOpen}
      placement="center"
      scrollBehavior="inside"
      onClose={closeModal}
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <div className="p-6">
          <div className="flex justify-start gap-7">
            <div className="mt-4">
              <PremiumIcon stroke="#344054" />
            </div>
            <div className="text-start">
              <h3 className="font-semibold text-color3 text-[18px]">
                Selecciona el Plan
              </h3>
              <p className="mt-1 font-normal text-[14px] text-color5">
                Consigue tu cuenta Premium y empieza a disfrutar de las ventajas
                de Loouth
              </p>
            </div>
          </div>
          <div className="mt-12 flex justify-center overflow-hidden overflow-y-auto max-h-[300px]">
            <div className="grid md:grid-cols-2 grid-cols-1 gap-[20px]">
              {isLoadingPlans ? (
                <>
                  {[1, 2].map((sk) => (
                    <Card
                      key={sk}
                      className="w-[286px] space-y-5 p-4 h-[278px] rounded-xl border-1 border-color1/60 shadow-none"
                      radius="lg"
                    >
                      <Skeleton className="rounded-lg mb-2 w-20">
                        <div className="h-8  rounded-lg bg-default-300"></div>
                      </Skeleton>
                      <Skeleton className="w-3/5 rounded-lg mb-4">
                        <div className="h-5 w-2/5 rounded-lg bg-default-300"></div>
                      </Skeleton>
                      <div className="space-y-5 mt-4">
                        <Skeleton className="w-3/5 rounded-lg">
                          <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                        </Skeleton>
                        <Skeleton className="w-4/5 rounded-lg">
                          <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                        </Skeleton>
                        <Skeleton className="w-2/5 rounded-lg">
                          <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                        </Skeleton>
                        <Skeleton className="w-3/5 rounded-lg">
                          <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                        </Skeleton>
                      </div>
                    </Card>
                  ))}
                </>
              ) : (
                <>
                  {plans.map((e) => (
                    <div key={e.id} onClick={() => setSelected(e)}>
                      <PlanCard
                        selected={selected}
                        id={e.id}
                        price={`${e.price / 100}€/${
                          e.periodo == "year" ? "año" : "mes"
                        }`}
                        title={e.name}
                        properties={[
                          "Chat Ilimitado",
                          "Acceso al buscador",
                          "Acceso a fiestas",
                          "Acceso a viajes",
                        ]}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="mt-14 md:flex block justify-between">
            <div>
              <a href="https://loouth.com/contacto">
                <SecondaryButton className="md:w-auto w-full">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.07847 9.85668C5.02668 9.52287 4.99981 9.1809 4.99981 8.83268C4.99981 5.15078 8.00421 2.16602 11.7103 2.16602C15.4165 2.16602 18.4209 5.15078 18.4209 8.83268C18.4209 9.66441 18.2675 10.4606 17.9875 11.1948C17.9293 11.3473 17.9002 11.4235 17.887 11.483C17.8739 11.542 17.8689 11.5835 17.8674 11.6439C17.866 11.7048 17.8743 11.772 17.8908 11.9063L18.2263 14.6315C18.2626 14.9265 18.2808 15.074 18.2317 15.1812C18.1887 15.2752 18.1123 15.3498 18.0174 15.3906C17.9091 15.4372 17.762 15.4156 17.4679 15.3725L14.8135 14.9834C14.675 14.9631 14.6056 14.953 14.5425 14.9533C14.4801 14.9537 14.4369 14.9583 14.3758 14.9711C14.314 14.9841 14.2351 15.0137 14.0773 15.0728C13.3412 15.3485 12.5435 15.4993 11.7103 15.4993C11.3618 15.4993 11.0196 15.473 10.6854 15.4221M6.35949 18.8327C8.83023 18.8327 10.8332 16.7807 10.8332 14.2493C10.8332 11.718 8.83023 9.66602 6.35949 9.66602C3.88874 9.66602 1.8858 11.718 1.8858 14.2493C1.8858 14.7582 1.96673 15.2476 2.11613 15.705C2.17928 15.8983 2.21085 15.9949 2.22122 16.061C2.23204 16.1299 2.23393 16.1686 2.2299 16.2383C2.22604 16.305 2.20935 16.3804 2.17596 16.5313L1.6665 18.8327L4.16217 18.4918C4.29839 18.4732 4.3665 18.4639 4.42598 18.4643C4.4886 18.4648 4.52184 18.4682 4.58326 18.4804C4.64159 18.492 4.7283 18.5226 4.90173 18.5838C5.35866 18.7451 5.84909 18.8327 6.35949 18.8327Z"
                      stroke="#344054"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Contacto
                </SecondaryButton>
              </a>
            </div>
            <div className="md:mt-0 mt-4 flex gap-[12px]">
              <SecondaryButton
                disabled={isLoading}
                className="md:w-auto w-full"
                onClick={closeModal}
              >
                Cancelar
              </SecondaryButton>
              <PrimaryButton
                loading={isLoading}
                className="md:w-auto w-full"
                onClick={makePayment}
              >
                Finalizar Compra
              </PrimaryButton>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
