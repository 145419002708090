import { Avatar } from "@nextui-org/react";
import { User } from "../../interfaces/Login";

export default function UserAvatar({
  user,
  classes,
  onClick,
}: {
  user: User | null;
  classes: string;
  onClick: () => void;
}) {
  return (
    <Avatar
      onClick={onClick}
      showFallback
      classNames={{
        base: "bg-zinc-100",
        icon: "text-black/50",
      }}
      src={
        user?.profile_path
          ? user?.profile_path.startsWith("http")
            ? user?.profile_path
            : import.meta.env.VITE_BASE_URL + user?.profile_path
          : undefined
      }
      className={classes}
    />
  );
}
