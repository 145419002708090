import { Card, Skeleton } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import { useRef } from "react";
import { Post } from "../../interfaces/Post";
import { ArrowLeftIcon, ArrowRightIcon } from "../../assets/icons/ArrowIcon";

export default function ProfileFeaturedContent({
  isLoadingPosts,
  posts,
}: {
  isLoadingPosts: boolean;
  posts: Post[];
}) {
  const containerRef = useRef(null);

  const handleScrollLeft = () => {
    if (containerRef.current) {
      (containerRef.current as HTMLDivElement).scrollLeft -= 360;
    }
  };
  const handleScrollRight = () => {
    if (containerRef.current) {
      (containerRef.current as HTMLDivElement).scrollLeft += 360;
    }
  };
  return (
    <div className="mt-2 flex flex-col items-center">
      <div
        ref={containerRef}
        className="flex scroll-smooth py-3 justify-start w-[calc(100vw-10px)] 2xl:w-[90rem] overflow-auto hide-scroll"
      >
        <div
          className={`mt-4 px-2 md:px-6 flex gap-8 min-w-[calc(${
            isLoadingPosts ? 4 : posts.length
          }*330px)] md:min-w-[calc(${
            isLoadingPosts ? 4 : posts.length
          }*376px)]`}
        >
          {posts.map((item, i) => (
            <div
              key={i}
              className="w-[330px] md:w-[376px] h-[273px] object-cover rounded-xl bg-slate-100"
            >
              <img
                className="w-[330px] md:w-[376px] h-[273px] object-cover rounded-xl"
                src={
                  item.content.startsWith("http")
                    ? item.content
                    : import.meta.env.VITE_BASE_URL + item.content
                }
              />
            </div>
          ))}
          {isLoadingPosts &&
            [0, 0, 0, 0].map((_, i) => (
              <Card
                key={i}
                className="w-[330px] md:w-[376px] h-[273px] space-y-5 p-4 rounded-xl shadow-none border-1 border-gray-200"
              >
                <Skeleton className="rounded-lg">
                  <div className="h-32 rounded-lg bg-default-300"></div>
                </Skeleton>
                <div className="space-y-3">
                  <Skeleton className="w-4/5 rounded-lg">
                    <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                  </Skeleton>
                  <Skeleton className="w-2/5 rounded-lg">
                    <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                  </Skeleton>
                </div>
              </Card>
            ))}
        </div>
      </div>

      <div className="w-full flex justify-center gap-8 mt-8 md:mt-14">
        <SecondaryButton
          className="font-semibold text-[14px] text-color4"
          onClick={handleScrollLeft}
        >
          <ArrowLeftIcon />
        </SecondaryButton>
        <SecondaryButton
          className="font-semibold text-[14px] text-color4"
          onClick={handleScrollRight}
        >
          <ArrowRightIcon />
        </SecondaryButton>
      </div>
    </div>
  );
}
