import { SVGProps } from "react";

export function FilterIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={props.stroke ?? "#888888"}
        d="M10 18v-2h4v2zm-4-5v-2h12v2zM3 8V6h18v2z"
      />
    </svg>
  );
}
