import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { User } from "../interfaces/Login";

interface AuthState {
  token: string;
  user: User | null;
  saveToken: (tk: string) => void;
  saveUser: (data: User | null) => void;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        token: "",
        user: null,
        saveToken: (tk) => set(() => ({ token: tk })),
        saveUser: (data) => set(() => ({ user: data })),
      }),
      { name: "authStore" }
    )
  )
);

export const infoDetals = () =>
  useAuthStore.getState().user?.info?.detalles ?? null;
export const infoOthers = () =>
  useAuthStore.getState().user?.info?.otros ?? null;
export const infoHowAmI = () =>
  useAuthStore.getState().user?.info?.como_soy ?? null;
