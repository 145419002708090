import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { FormEvent, useEffect, useState } from "react";
import { Fantasy } from "../interfaces/Fantasy";
import { getFantasies } from "../api/formSelectsData";
import FantasiesList from "../components/fantasy/FantasiesList";
import { Button, Input } from "@nextui-org/react";
import { SearchIcon } from "../assets/icons/SearchIcon";

export default function Fantasies() {
  const [info, setInfo] = useState({
    fantasies: [] as Fantasy[],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setSearch(data.value);
  };
  const getData = async () => {
    setIsLoading(true);

    const fantasies = await getFantasies(search);
    setInfo({
      ...info,
      fantasies: fantasies?.info?.data[0] ?? [],
    });
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log("here");
    getData();
  };
  return (
    <div className=" h-screen">
      <NavBar />
      <main className="flex items-center  w-full overflow-hidden pt-10 md:pt-18 min-h-[100vh] flex-col">
        <div className="px-4 md:px-14 w-full max-w-8xl">
          <section className="w-full ">
            <div className="mt-2 flex flex-col items-start">
              <h1 className="text-2xl font-semibold">Fantasías</h1>
              <p>
                Aquí encontrarás las fantasías que tienen planeados los usuarios
                Loouth. Publica tus propias fantasías y vive experiencias cerca
                de tí.
              </p>
              <form onSubmit={handleSubmit} className="w-full mt-6">
                <Input
                  className="min-w-sm text-input w-full "
                  id="search"
                  type="text"
                  variant="bordered"
                  label=""
                  startContent={<SearchIcon />}
                  placeholder="Buscar..."
                  value={search}
                  onChange={handleChange}
                  endContent={
                    <Button
                      color="primary"
                      variant="solid"
                      className="rounded-lg "
                      type="submit"
                      size="sm"
                    >
                      Buscar
                    </Button>
                  }
                  classNames={{ inputWrapper: " border-1 rounded-lg" }}
                />
              </form>
            </div>
          </section>
        </div>
        <FantasiesList isLoading={isLoading} fantasies={info.fantasies} />
      </main>
      <Footer />
    </div>
  );
}
