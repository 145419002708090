export const getPlaceByLatLng = async (lat: number, lng: number) => {
  const geocoder = new google.maps.Geocoder();
  const results = await geocoder.geocode({
    location: { lat, lng },
    language: "es",
  });
  if (results) return results;
};
export const getPlaceFormated = async (lat: number, lng: number) => {
  const place = await getPlaceByLatLng(lat, lng);

  // console.log(place);
  return place?.results[0].formatted_address;
};
