import { Card, CardBody, CardFooter, Image } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { User } from "../../interfaces/Login";
import PrimaryButton from "../common/PrimaryButton";
import { Party } from "../../interfaces/Party";
import { useState } from "react";
import { requestJoinParty } from "../../api/party";
import { toast } from "react-toastify";

export default function PartyUserCard({
  user,
  party,
  onSendRequest,
}: {
  user: User;
  party: Party;
  onSendRequest: (id: number) => void;
}) {
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const handleRequestJoin = async () => {
    setIsSendingRequest(true);
    const payload = {
      fiesta_id: party.id,
      user_id: user.id,
    };
    const res = await requestJoinParty(payload);
    // console.log(res);
    if (res.info && res.error == "false" && party && user) {
      toast.success("Usuario invitado con éxito");
      onSendRequest(res.info.data[0].id);
    } else {
      if (
        res.error == "true" &&
        res.info.message == "This user was invited before"
      ) {
        toast.error(
          "No puedes invitar este usuario, debido a una previa solicitud rechazada"
        );
      } else toast.error("Ha ocurrido un error");
    }
    setIsSendingRequest(false);
  };
  const isRequest = () => {
    const request = party.solicitudes.find((req) => req.user.id == user.id);
    if (request?.estado == "pendiente") return true;
    return false;
  };
  const isMember = () => {
    const exist = party.integrantes.find((u) => u.id == user.id);
    // console.log(party.solicitudes.find((req) => id == 53));
    if (exist) return true;
    return false;
  };
  return (
    <Card shadow="sm" radius="md" className="w-full h-[250px]">
      <CardBody className="overflow-visible p-0">
        <Link to={`/profile/${user.id}`}>
          <Image
            shadow="none"
            radius="md"
            width="100%"
            isZoomed
            alt="alt"
            className="w-full object-cover h-[140px]"
            fallbackSrc="/assets/fantasyImage.jpeg"
            src={
              user?.profile_path
                ? user?.profile_path?.startsWith("http")
                  ? user?.profile_path
                  : import.meta.env.VITE_BASE_URL + user?.profile_path
                : undefined
            }
          />
        </Link>
      </CardBody>
      <CardFooter className="text-small flex flex-col items-start">
        <Link to={`/profile/${user.id}`} key={user.id}>
          <b className="hover:text-primary text-lg">{user.name}</b>
        </Link>
        {isRequest() && (
          <PrimaryButton className="mt-2 w-full" disabled>
            Pendiente
          </PrimaryButton>
        )}
        {isMember() && (
          <PrimaryButton className="mt-2 w-full" disabled>
            Integrante
          </PrimaryButton>
        )}
        {party.user_id == user.id && (
          <PrimaryButton className="mt-2 w-full" disabled>
            Autor
          </PrimaryButton>
        )}
        {!isRequest() && !isMember() && party.user_id != user.id && (
          <PrimaryButton
            className="mt-2 w-full"
            onClick={handleRequestJoin}
            loading={isSendingRequest}
          >
            Invitar
          </PrimaryButton>
        )}
      </CardFooter>
    </Card>
  );
}
