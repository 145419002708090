import { useMemo } from "react";
import { FriendReq, User } from "../../interfaces/Login";
import { Link } from "react-router-dom";
import { Avatar } from "@nextui-org/react";

export default function MyFriends({ friends = [] }: { friends: FriendReq[] }) {
  const data = useMemo(() => {
    const res = friends.map((e) => {
      const user = e.to == "me" ? e.from : e.to;
      return {
        ...(user as User),
        // id: e.id,
      };
    });
    return res ?? [];
  }, [friends]);

  return (
    <section className="mt-10">
      <h3 className="font-bold text-[16px] text-black">Mis Amigos</h3>
      <ul className="mt-6 flex flex-col gap-3">
        {data.map((e) => (
          <li key={e.id} className="flex gap-3">
            <Link to={`/profile/${e?.id}`}>
              <Avatar
                showFallback
                classNames={{
                  base: "bg-zinc-100",
                  icon: "text-black/50",
                  name: "text-black/50 capitalize font-bold text-[16px]",
                }}
                src={
                  e?.profile_path
                    ? e?.profile_path.startsWith("http")
                      ? e?.profile_path
                      : import.meta.env.VITE_BASE_URL + e?.profile_path
                    : undefined
                }
              />
              <div
                className={
                  "relative -top-3 -right-8 w-3 h-3 rounded-full" +
                  (e.status == "disconnected"
                    ? ""
                    : " border-2 border-white bg-green-500")
                }
              ></div>
            </Link>
            <div className="flex flex-col">
              <label className="font-medium text-[14px] text-color2">
                <Link className="hover:text-primary" to={`/profile/${e?.id}`}>
                  {e.name}
                </Link>
              </label>
              <span className="font-mormal text-[14px] text-color5">
                {e.status == "disconnected" ? "Desconectado" : "En linea"}
              </span>
            </div>
          </li>
        ))}
        {friends.length == 0 && (
          <li>
            <div className="flex flex-col">
              <span className="font-mormal text-[14px] text-color1">
                Aun no tienes amigos..
              </span>
            </div>
          </li>
        )}
      </ul>
    </section>
  );
}
