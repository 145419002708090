import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@nextui-org/react";
import { Global } from "../../interfaces/SingleUser";
import {
  GlobalFriendsIcon,
  GlobalReactionsIcon,
  GlobalVisitsIcon,
} from "../../assets/icons/GlobalDataIcons";

export default function ProfileGlobalData({
  globalData,
  isMinimunVersion,
}: {
  globalData: Global;
  isMinimunVersion?: boolean;
}) {
  return (
    <>
      <Tooltip content="Visitas" placement="bottom">
        <div className="hidden lg:flex items-center gap-2 border-1 border-color2/15 text-color1 font-semibold  px-2 py-1 rounded-lg">
          <div className="">
            <GlobalVisitsIcon />
          </div>
          <span className="">{globalData.visits + 1}</span>
        </div>
      </Tooltip>
      <Popover placement="bottom" className="">
        <PopoverTrigger>
          <div
            className={`flex lg:hidden items-center   text-color1 font-semibold  py-1 rounded-lg ${
              isMinimunVersion
                ? " px-1"
                : "  px-2 border-1 gap-2 border-color2/15"
            } `}
          >
            <div className="">
              <GlobalVisitsIcon />
            </div>
            <span className="">{globalData.visits + 1}</span>
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <div className="p-1">
            <div className="text-small">Visitas</div>
          </div>
        </PopoverContent>
      </Popover>

      <Tooltip content="Amigos" placement="bottom">
        <div className="hidden lg:flex items-center gap-1 border-1 border-color2/15 text-color1 font-semibold  px-2 py-1 rounded-lg">
          <div>
            <GlobalFriendsIcon />
          </div>
          <span className="">{globalData.friends}</span>
        </div>
      </Tooltip>
      <Popover placement="bottom" className="">
        <PopoverTrigger>
          <div
            className={`flex lg:hidden items-center  text-color1 font-semibold py-1 rounded-lg ${
              isMinimunVersion
                ? " px-1"
                : " px-2 border-1 gap-2  border-color2/15"
            } `}
          >
            <div>
              <GlobalFriendsIcon />
            </div>
            <span className="">{globalData.friends}</span>
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <div className="p-1">
            <div className="text-small">Amigos</div>
          </div>
        </PopoverContent>
      </Popover>
      <Tooltip content="Likes" placement="bottom">
        <div className="hidden lg:flex items-center gap-2 border-1 border-color2/15 text-color1 font-semibold px-2 py-1 rounded-lg">
          <div>
            <GlobalReactionsIcon />
          </div>
          <span className="">{globalData.reactions}</span>
        </div>
      </Tooltip>
      <Popover placement="bottom" className="">
        <PopoverTrigger>
          <div
            className={`flex lg:hidden items-center   text-color1 font-semibold  px-2 py-1 rounded-lg ${
              isMinimunVersion
                ? "  px-1"
                : "border-1   px-2 gap-2 border-color2/15"
            } `}
          >
            <div>
              <GlobalReactionsIcon />
            </div>
            <span className="">{globalData.reactions}</span>
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <div className="p-1">
            <div className="text-small">Likes</div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}
