import { Select, SelectItem, Spinner } from "@nextui-org/react";
import VisibilityState from "./VisibilityState";
import { FormEvent, useEffect, useState } from "react";

export default function CustomSelect({
  name = "",
  visibility_name = "",
  title = "Custom Select",
  required = false,
  items = [],
  onVisibilityChange = () => {},
  visibilityAll = false,
  placeholder = "Seleccione",
  selectionMode = "single",
  onChange = () => {},
  value = "",
  showVisibility = true,
  defaultSelectedKeys = [],
  errorMessage = "Este campo es requerido",
  isInvalid = false,
}: {
  name?: string;
  visibility_name?: string;
  title?: string;
  required?: boolean;
  items?: {
    label: string;
    value: string;
  }[];
  selectionMode?: "single" | "multiple";
  onVisibilityChange?: (value: string) => void;
  visibilityAll?: boolean;
  placeholder?: string;
  onChange?: (e: FormEvent<HTMLSelectElement>) => void;
  value?: string;
  showVisibility?: boolean;
  defaultSelectedKeys?: string[];
  errorMessage?: string;
  isInvalid?: boolean;
}) {
  // const [defSelected, setDefSelected] = useState<string[]>([]);
  // useEffect(() => {
  //   if (items.length && value) {
  //     //const aux = items.map((e) => e.value);
  //     setDefSelected([value]);
  //     // console.log(name, value);
  //   }
  // }, [items]);
  const [isValid, setIsValid] = useState(!isInvalid);
  const handleChangeInput = (e: FormEvent<HTMLSelectElement>) => {
    const data = e.target as HTMLInputElement;
    if (!data.value && required) {
      setIsValid(false);
    } else setIsValid(true);
    onChange(e);
  };
  useEffect(() => {
    // console.log(isInvalid);
    setIsValid(!isInvalid);
  }, [isInvalid]);

  return (
    <div>
      <Select
        selectionMode={selectionMode}
        variant="bordered"
        label={title}
        placeholder={placeholder}
        name={name}
        errorMessage={errorMessage}
        isInvalid={!isValid}
        // isRequired={required}
        labelPlacement="outside"
        classNames={{
          trigger: "h-[44px] rounded-lg border-small",
          label:
            "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
          popoverContent: "rounded-md",
        }}
        className="w-full"
        onChange={handleChangeInput}
        value={value}
        defaultSelectedKeys={defaultSelectedKeys}
        startContent={
          !items?.length ? <Spinner size="sm" color="primary" /> : undefined
        }
        isDisabled={!items?.length}
      >
        {items.map((item) => (
          <SelectItem key={item.value} value={item.value}>
            {item.label}
          </SelectItem>
        ))}
      </Select>
      {showVisibility && (
        <VisibilityState
          onVisibilityChange={onVisibilityChange}
          visibilityAll={visibilityAll}
          visibility_name={visibility_name}
        />
      )}
    </div>
  );
}
