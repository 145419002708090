import { Card, CardBody, CardFooter, Image, Skeleton } from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import { Link } from "react-router-dom";
import SecondaryButton from "../common/SecondaryButton";
import { useMemo, useState } from "react";
import { RequestData } from "../../interfaces/Login";
import { useAuthStore } from "../../stores/authStore";
import { deleteFriendRequest, respondFriendRequest } from "../../api/friends";
import { toast } from "react-toastify";
import { useHomeStore } from "../../stores/homeData";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import { Province } from "../../interfaces/Provice";

export default function FriendCard({
  data,
  userId,
  type,
  orientations,
  provinces,
  setFriendToDelete,
  setFriendRequestsSent,
  onSuccessAccept,
  onSuccessReject,
}: {
  data: RequestData;
  userId?: number;
  type: "friend" | "request" | "requestSent";
  setFriendToDelete: () => void;
  setFriendRequestsSent: () => void;
  onSuccessAccept: () => void;
  onSuccessReject: () => void;
  orientations: SexualOrientation[];
  provinces: Province[];
}) {
  const [isSendingrequest, setIsSendingrequest] = useState(false);
  const [isCancelingrequest, setIsCancelingrequest] = useState(false);
  const [isSendingReject, setIsSendingReject] = useState(false);

  const authData = useAuthStore((state) => state.user);

  const setHome = useHomeStore((state) => state.setHome);
  const home = useHomeStore((state) => state.home);

  const onCancelRequest = async () => {
    setIsCancelingrequest(true);
    const response = await deleteFriendRequest(data.reqId);
    if (response.error == "true" || !response.info)
      toast.error(response?.info?.message || "Ha ocurrido un error");
    else {
      toast.success("Solicitud cancelada con éxito");
      setFriendRequestsSent();
    }

    setIsCancelingrequest(false);
  };

  const sendRequest = async (status: string) => {
    if (status == "accept") {
      setIsSendingrequest(true);
    } else setIsSendingReject(true);
    const response = await respondFriendRequest(data.reqId, { status });
    if (response.error == "true" || !response.info)
      toast.error(response?.info?.message || "Ha ocurrido un error");
    else {
      toast.success(
        status == "accept"
          ? "Solicitud aceptada con éxito"
          : "Solicitud rechazada con éxito"
      );

      if (status == "accept") {
        onSuccessAccept();
        setHome({
          ...home,
          amigos: [...home.amigos, response?.info.data[0]],
        });
      } else onSuccessReject();
    }

    status == "accept" ? setIsSendingrequest(false) : setIsSendingReject(false);
  };
  const orientation = useMemo(() => {
    const userSO = data?.info?.detalles?.sexualOrentation;
    const userPartnerSO = data?.info?.detalles?.partner_sexualOrentation;
    if (orientations.length) {
      const myO =
        userSO?.value &&
        orientations.find((e) => e.id.toString() == userSO?.value)?.name;

      if (!data?.info?.is_couple) return myO ?? "?";

      const partnerO =
        userPartnerSO?.value &&
        orientations.find((e) => e.id.toString() == userPartnerSO?.value)?.name;

      if (myO && partnerO) {
        if (myO == partnerO) return myO;
        return myO == "bisexual" ? partnerO : myO;
      }
      return "?";
    } else
      return (
        <Skeleton className="ml-2 rounded-lg">
          <p className="w-14 h-3"></p>
        </Skeleton>
      );
  }, [orientations]);
  const age = useMemo(() => {
    if (!data?.info?.detalles?.birthDate) return "?";
    const birthday = new Date(data?.info?.detalles?.birthDate);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    if (data.info.is_couple && data?.info?.detalles?.partner_birthDate) {
      const partnerBirthday = new Date(data?.info?.detalles?.partner_birthDate);
      const partnerAgeDifMs = Date.now() - partnerBirthday.getTime();
      const partnerAgeDate = new Date(partnerAgeDifMs); // miliseconds from epoch
      const uAge = Math.abs(ageDate.getUTCFullYear() - 1970);
      const pAge = Math.abs(partnerAgeDate.getUTCFullYear() - 1970);
      if (uAge == pAge) return `ambos ${pAge}`;
      return `${uAge} y ${pAge}`;
    }
    //partner_birthDate
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }, [data]);
  const province = useMemo(() => {
    if (provinces.length && data?.info?.provincia_id) {
      const pr = provinces.find((e) => e.id == data?.info?.provincia_id)?.name;
      return pr ?? "";
    }
    return (
      <Skeleton className="ml-2 rounded-lg">
        <p className="w-14 h-3"></p>
      </Skeleton>
    );
  }, [provinces]);
  return (
    <Card
      shadow="none"
      className="shadow-none border-1  border-gray-200 w-full"
    >
      <CardBody className="overflow-visible  p-1 ">
        <div className="relative object-fit">
          <Link to={`/profile/${data?.id}`}>
            <Image
              radius="md"
              width="100%"
              alt="post title here"
              isZoomed
              className="w-full object-cover h-[200px] md:h-[140px]"
              fallbackSrc="/assets/fantasyImage.jpeg"
              src={
                data?.profile_path
                  ? data?.profile_path.startsWith("http")
                    ? data?.profile_path
                    : import.meta.env.VITE_BASE_URL + data?.profile_path
                  : undefined
              }
            />
          </Link>
        </div>
      </CardBody>

      <CardFooter className="flex flex-col text-start items-start">
        <p>
          <b className="hover:text-primary text-xl">
            <Link to={`/profile/${data.id}`} key={data.id}>
              {data.name}
            </Link>
          </b>
          <span className="text-[16px] ml-3 font-bold text-primary">
            {age} años
          </span>
        </p>
        <p
          onClick={() => console.log(data.info)}
          className="font-semibold text-color1 mb-4"
        >
          {" "}
          <span className="font-semibold text-color1">
            {data.info?.is_couple
              ? "Pareja"
              : data.info?.detalles?.sex?.value == "f"
              ? "Mujer"
              : "Hombre"}{" "}
            {orientation} -
            <span className="text-blue-500 font-bold ml-2">{province}</span>
          </span>
        </p>
        {type == "friend" ? (
          <>
            <PrimaryButton
              onClick={setFriendToDelete}
              className={`w-full ${userId != authData?.id ? "hidden" : ""}`}
            >
              Eliminar
            </PrimaryButton>
          </>
        ) : type == "request" ? (
          <>
            <PrimaryButton
              loading={isSendingrequest}
              onClick={() => sendRequest("accept")}
              className="w-full mb-2"
            >
              Aceptar
            </PrimaryButton>
            <SecondaryButton
              className="w-full "
              loading={isSendingReject}
              onClick={() => sendRequest("reject")}
            >
              Rechazar
            </SecondaryButton>
          </>
        ) : (
          <PrimaryButton
            onClick={onCancelRequest}
            className="w-full"
            loading={isCancelingrequest}
          >
            Cancelar
          </PrimaryButton>
        )}
      </CardFooter>
    </Card>
  );
}
