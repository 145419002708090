import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Image,
  Tooltip,
} from "@nextui-org/react";
import { Fantasy } from "../../interfaces/Fantasy";
import { Link } from "react-router-dom";
import { StarIcon } from "../../assets/icons/StarIcon";
import { useState } from "react";

export default function FantasyCard({
  fantasy,
  isChecked = false,
  handleLike,
}: {
  fantasy: Fantasy;
  isChecked?: boolean;
  handleLike: () => void;
}) {
  const [checked, setCheck] = useState(isChecked);
  const handleChack = () => {
    setCheck(!checked);
    handleLike();
  };
  return (
    <Card
      shadow="none"
      className="shadow-none border-1  border-gray-200 w-full"
    >
      <CardBody className="overflow-visible  p-1 ">
        <div className="relative object-fit">
          <Link to={`/fantasy/${fantasy.id}`}>
            <Image
              radius="md"
              width="100%"
              alt="post title here"
              isZoomed
              className="w-full object-cover h-[200px] md:h-[140px]"
              fallbackSrc="/assets/fantasyImage.jpeg"
              src={
                fantasy.avatar
                  ? fantasy.avatar?.startsWith("http")
                    ? fantasy.avatar
                    : import.meta.env.VITE_BASE_URL +
                      "storage/" +
                      fantasy.avatar
                  : undefined
              }
            />
          </Link>

          <div className="absolute top-2 left-2 z-10">
            <Tooltip content="Añadir a mis fantasías" placement="top">
              <Button
                className={`bg-color8/70 like ${checked ? "checked" : ""}`}
                variant="flat"
                isIconOnly
                radius="sm"
                aria-label="like"
                onClick={handleChack}
              >
                <div className="checkmark flex items-center justify-center">
                  <StarIcon height={24} width={24} />
                </div>
              </Button>
            </Tooltip>
          </div>
        </div>
      </CardBody>

      <CardFooter className="flex flex-col text-start items-start">
        <p className="text-lg font-bold">{fantasy.name}</p>
        <p className="text-default-500">
          {fantasy.description.slice(0, 100) + "..."}
        </p>
      </CardFooter>
    </Card>
  );
}
