import { SVGProps } from "react";

export function CommentIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H14.2C15.8802 1 16.7202 1 17.362 1.32698C17.9265 1.6146 18.3854 2.07354 18.673 2.63803C19 3.27976 19 4.11984 19 5.8V11.2C19 12.8802 19 13.7202 18.673 14.362C18.3854 14.9265 17.9265 15.3854 17.362 15.673C16.7202 16 15.8802 16 14.2 16H11.6837C11.0597 16 10.7477 16 10.4492 16.0613C10.1844 16.1156 9.9282 16.2055 9.68749 16.3285C9.41617 16.4671 9.17252 16.662 8.68521 17.0518L6.29976 18.9602C5.88367 19.2931 5.67563 19.4595 5.50054 19.4597C5.34827 19.4599 5.20422 19.3906 5.10923 19.2716C5 19.1348 5 18.8684 5 18.3355V16C4.07003 16 3.60504 16 3.22354 15.8978C2.18827 15.6204 1.37962 14.8117 1.10222 13.7765C1 13.395 1 12.93 1 12V5.8Z"
        stroke={props.stroke ?? "#919DAD"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
