import { Textarea } from "@nextui-org/react";
import { FormEvent, useEffect, useState } from "react";

export default function CustomTextAreaInput({
  name = "",
  title = "Custom Date Input",
  required = false,
  placeholder = "",
  id,
  onChange = () => {},
  className = "col-span-12 md:col-span-6 mb-6 md:mb-0",
  errorMessage = "Este campo es requerido",
  endContent = null,
  value = "",
  isInvalid = false,
}: {
  id?: string;
  name?: string;
  visibility_name?: string;
  title?: string;
  required?: boolean;
  onVisibilityChange?: (value: string) => void;
  visibilityAll?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
  className?: string;
  errorMessage?: string;
  minLength?: number;
  endContent?: React.ReactNode;
  isInvalid?: boolean;
}) {
  const [isValid, setIsValid] = useState(!isInvalid);
  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    if (!data.value && required) {
      setIsValid(false);
    } else setIsValid(true);
    onChange(e);
  };
  useEffect(() => {
    setIsValid(!isInvalid);
  }, [isInvalid]);

  return (
    <Textarea
      id={id}
      name={name}
      label={title}
      isRequired={required}
      isInvalid={!isValid}
      variant="bordered"
      labelPlacement="outside"
      placeholder={placeholder}
      className={className}
      errorMessage={errorMessage}
      classNames={{
        inputWrapper: "rounded-lg border-small",
        label: "text-[14px] font-medium text-color2",
        input: "border-0 focus:ring-1 focus:ring-transparent",
      }}
      endContent={endContent}
      onChange={handleChangeInput}
      value={value}
    />
  );
}
