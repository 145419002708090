import { SVGProps } from "react";

export function MenuIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99984 11.334C10.4601 11.334 10.8332 10.9609 10.8332 10.5007C10.8332 10.0404 10.4601 9.66732 9.99984 9.66732C9.5396 9.66732 9.1665 10.0404 9.1665 10.5007C9.1665 10.9609 9.5396 11.334 9.99984 11.334Z"
        stroke="#344054"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99984 5.50065C10.4601 5.50065 10.8332 5.12755 10.8332 4.66732C10.8332 4.20708 10.4601 3.83398 9.99984 3.83398C9.5396 3.83398 9.1665 4.20708 9.1665 4.66732C9.1665 5.12755 9.5396 5.50065 9.99984 5.50065Z"
        stroke="#344054"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99984 17.1673C10.4601 17.1673 10.8332 16.7942 10.8332 16.334C10.8332 15.8737 10.4601 15.5007 9.99984 15.5007C9.5396 15.5007 9.1665 15.8737 9.1665 16.334C9.1665 16.7942 9.5396 17.1673 9.99984 17.1673Z"
        stroke="#344054"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
