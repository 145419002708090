import { Avatar, AvatarGroup, Chip, Image, Tooltip } from "@nextui-org/react";
import { Post } from "../../interfaces/Post";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { FormEvent, ReactNode, useMemo, useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import {
  addPostReaction,
  deleteHighlightPost,
  deletePostSaved,
  highlightPost,
  savePost,
} from "../../api/publication";
import CommentPostModal from "./CommentPostModal";
import { SaveIcon } from "../../assets/icons/SaveIcon";
import { CommentIcon } from "../../assets/icons/CommentIcon";
import { HeartIcon } from "../../assets/icons/HeartIcon";
// import VideoPlayer from "../common/VideoPlayer";
import LightBoxImage from "../common/LightBoxImage";
import { useHomeStore } from "../../stores/homeData";
import { HighlightIcon } from "../../assets/icons/HighlightIcon";

export default function PostCard({
  minimumVersion = false,
  className = "",
  post,
  fantasy,
  slot,
  slotDescription,
  onUnsave,
}: {
  minimumVersion?: boolean;
  className?: string;
  post: Post;
  fantasy?: string;
  slot?: ReactNode;
  slotDescription?: ReactNode;
  onUnsave?: () => void;
}) {
  const [ckeck, setCheck] = useState(post.info.myReacted);
  const [saved, setSaved] = useState(post.info.isSaved);
  const [highlighted, setHighlighted] = useState(post.info.isDestacado);
  const [newLike, setNewLike] = useState(false);
  const [showCommentArea, setShowCommentArea] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [isOpenLightbox, setIsOpenLightbox] = useState({
    open: false,
    type: "image" as "image" | "video",
    poster: "",
    src: "",
  });
  const authData = useAuthStore((state) => state.user);
  const reactionType = useHomeStore((state) => state.reactionType);

  const handleReact = async (value: boolean) => {
    setCheck(value);
    setNewLike(value);

    if (value)
      await addPostReaction({
        publicacion_id: post.id,
        reaccion_id: reactionType, // TODO: integrate all type of reactions
      });
    else {
      await addPostReaction({
        publicacion_id: post.id,
        reaccion_id: 0,
      });
      // console.log(res);
    }
  };

  const onCheck = async (e: FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const value = input.checked;
    //
    await handleReact(value);
  };
  const url = useMemo(() => {
    if (!post.hasMedia) return "";
    let res = "";
    if (!post.content.startsWith("http")) res += import.meta.env.VITE_BASE_URL;

    res += post.content;
    if (post.media == "video") {
      res = res.replace("content", "frame.jpg");
      // console.log(res);
    }

    return res;
  }, [post.media]);

  const urlVideo = useMemo(() => {
    if (!post.hasMedia || post.media != "video") return "";
    let res = "";
    if (!post.content.startsWith("http")) res += import.meta.env.VITE_BASE_URL;

    res += post.content;

    return res;
  }, [post.media]);

  const lastReactions = useMemo(() => {
    if (
      !ckeck ||
      (ckeck &&
        newLike &&
        post.info?.lastReacciones?.find((it) => it.user_id == authData?.id))
    )
      return post.info.lastReacciones.filter(
        (it) => it.user_id != authData?.id
      );

    return post?.info?.lastReacciones;
  }, [ckeck]);
  const handleComment = () => {
    setShowCommentArea(!showCommentArea);
    setShowComments(true);
  };

  const handleSave = async () => {
    // console.log(saved);
    setSaved(!saved);
    if (!saved) {
      await savePost({
        publicacion_id: post.id,
      });
      // console.log(res);
    } else if (post.info.save) {
      await deletePostSaved(post.info.save.id);
      if (onUnsave) onUnsave();
    }
  };
  const handleHighlight = async () => {
    // console.log(saved);
    setHighlighted(!highlighted);
    if (!highlighted) {
      const res = await highlightPost(post.id);
      console.log("res", res);
    } else if (post.info.save) {
      await deleteHighlightPost(post.info.save.id);
    }
  };
  return (
    <article className={"flex flex-col " + className}>
      <div className="flex items-center gap-4 md:gap-8">
        <Link to={`/profile/${post.user?.id}`}>
          <Avatar
            showFallback
            classNames={{
              base: "bg-zinc-100",
              icon: "text-black/50",
            }}
            className="w-14 h-14 md:w-[68px] md:h-[68px]"
            src={
              post.user?.profile_path
                ? post.user?.profile_path.startsWith("http")
                  ? post.user?.profile_path
                  : import.meta.env.VITE_BASE_URL + post.user?.profile_path
                : undefined
            }
          />
        </Link>
        <div className="flex flex-col justify-between w-full">
          <label className="font-bold text-[21px] text-back">
            <Link
              className="hover:text-primary"
              to={`/profile/${post.user?.id}`}
            >
              {" "}
              {post.user?.name}
            </Link>
          </label>
          <label className="font-bold text-[16px] text-color7">
            {post.created_at && (
              <Moment fromNow locale="Es">
                {new Date(post.created_at) ?? "1976-04-19T12:59-0500"}
              </Moment>
            )}
          </label>
        </div>
        {fantasy && post.fantasia_id && (
          <Link to={`/fantasy/${post.fantasia_id}`}>
            <Chip variant="flat" color="primary" className="mt-2 font-semibold">
              <span className="font-semibold">{fantasy}</span>
            </Chip>
          </Link>
        )}
        {slot}
        {post.user_id == authData?.id && minimumVersion && post.media && (
          <Tooltip content="Añadir a destacados" placement="top">
            <div
              onClick={handleHighlight}
              className={`flex items-center -top-4 focus:ring-transparent like ${
                highlighted ? "checked" : ""
              }`}
            >
              <div className="checkmark flex items-center justify-center">
                <HighlightIcon className="size-9 cursor-pointer" />
              </div>
            </div>
          </Tooltip>
        )}
      </div>
      <p
        hidden={post.media == null ? false : true}
        className="mt-8 font-medium text-[16px] text-black flex-1"
      >
        {post.content}
      </p>
      <div
        hidden={post.media == "image" || post.media == "video" ? false : true}
        className={"mt-6 relative"}
      >
        {minimumVersion ? (
          <>
            <div
              className="object-cover w-full rounded-2xl overflow-hidden h-[250px] bg-cover bg-center"
              style={{
                backgroundImage: ` url(${url})`,
              }}
            >
              {post.visibility == "private" && authData?.id != post.user_id && (
                <div className="w-full h-full backdrop-blur-lg bg-white/10"></div>
              )}
            </div>
          </>
        ) : post.media == "video" ? (
          <div className="object-cover w-full rounded-2xl overflow-hidden">
            <img
              src={url}
              className="object-cover w-full rounded-2xl cursor-pointer"
            />
          </div>
        ) : (
          <div className=" relative rounded-2xl  overflow-hidden">
            <Image
              shadow="none"
              width="100%"
              alt="alt"
              className="w-full object-cover rounded-2xl min-h-[300px]"
              src={url}
              onClick={() =>
                setIsOpenLightbox({
                  open: true,
                  type: "image",
                  poster: "",
                  src: url,
                })
              }
            ></Image>
            {post.visibility == "private" && authData?.id != post.user_id && (
              <div className="absolute overflow-hidden rounded-2xl top-0 z-10 w-full h-full backdrop-blur-lg bg-white/10"></div>
            )}
          </div>
          // <img

          //   }
          //   src={url}
          //   className="object-cover w-full rounded-2xl cursor-pointer"
          // />
        )}
        {/* <img
          src={import.meta.env.VITE_BASE_URL + post.content}
          className="object-cover w-full rounded-2xl"
        /> */}
        {post.media == "video" && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/20">
            <div
              className="hover:opacity-55 cursor-pointer"
              onClick={() =>
                setIsOpenLightbox({
                  open: true,
                  type: "video",
                  poster: url,
                  src: urlVideo,
                })
              }
            >
              <svg
                width="70"
                height="90"
                viewBox="0 0 130 130"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M65.0001 128.333C99.9781 128.333 128.333 99.9774 128.333 64.9993C128.333 30.0213 99.9781 1.66602 65.0001 1.66602C30.022 1.66602 1.66675 30.0213 1.66675 64.9993C1.66675 99.9774 30.022 128.333 65.0001 128.333Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M49.1667 45.7798C49.1667 42.757 49.1667 41.2456 49.7984 40.4018C50.3489 39.6665 51.1916 39.2064 52.1078 39.141C53.1592 39.0659 54.4305 39.8832 56.9732 41.5178L86.8703 60.7374C89.0768 62.1558 90.18 62.865 90.5611 63.7668C90.894 64.5548 90.894 65.4439 90.5611 66.2319C90.18 67.1337 89.0768 67.8429 86.8703 69.2613L56.9733 88.4809C54.4305 90.1155 53.1592 90.9328 52.1078 90.8577C51.1916 90.7923 50.3489 90.3322 49.7984 89.5969C49.1667 88.7531 49.1667 87.2417 49.1667 84.2189V45.7798Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        )}
      </div>
      <div hidden={minimumVersion}>
        <div className="mt-6 flex justify-between">
          <AvatarGroup max={3}>
            {newLike && (
              <Link key={authData?.id} to={`/profile/${authData?.id}`}>
                <Avatar
                  showFallback
                  classNames={{
                    base: "bg-zinc-100 border-2 border-white",
                  }}
                  name={authData?.name}
                  src={
                    authData?.profile_path
                      ? authData?.profile_path.startsWith("http")
                        ? authData?.profile_path
                        : import.meta.env.VITE_BASE_URL + authData?.profile_path
                      : undefined
                  }
                />
              </Link>
            )}
            {lastReactions?.length &&
              lastReactions?.map((it) => (
                <Link key={it.user?.id} to={`/profile/${it.user?.id}`}>
                  <Avatar
                    showFallback
                    classNames={{
                      base: "bg-zinc-100 border-2 border-white",
                    }}
                    name={it.user?.name}
                    src={
                      it.user?.profile_path
                        ? it.user?.profile_path.startsWith("http")
                          ? it.user?.profile_path
                          : import.meta.env.VITE_BASE_URL +
                            it.user?.profile_path
                        : undefined
                    }
                  />
                </Link>
              ))}
          </AvatarGroup>
          <span
            className="font-bold text-[16px] text-color7 cursor-pointer"
            onClick={handleComment}
          >
            {" "}
            Comentarios {post.info?.comentarios}
          </span>
        </div>
      </div>
      <hr hidden={minimumVersion} className="mt-6 border-color7"></hr>
      <div className="mt-6 grid grid-cols-3 justify-center gap-8">
        <div className="flex  items-center justify-center md:justify-start gap-4">
          <label className="like">
            <input onChange={onCheck} checked={ckeck} type="checkbox"></input>
            <div className="checkmark flex">
              <HeartIcon height={24} width={24} />
            </div>
          </label>
          <span
            onClick={() => handleReact(!ckeck)}
            className="hidden md:inline font-bold text-[16px] text-color7 cursor-pointer"
          >
            Me gusta
          </span>
        </div>
        <button
          onClick={handleComment}
          className="flex items-center justify-center md:justify-start gap-4 cursor-pointer focus:ring-transparent"
        >
          <CommentIcon />
          <span className="hidden md:inline  font-bold text-[16px] text-color7">
            Comentar
          </span>
        </button>
        <button
          onClick={handleSave}
          className={`flex items-center justify-center md:justify-start gap-4`}
        >
          <div
            className={`flex items-center focus:ring-transparent like ${
              saved ? "checked" : ""
            }`}
          >
            <div className="checkmark flex items-center justify-center">
              <SaveIcon />
            </div>
          </div>

          <label className="hidden md:inline  font-bold text-[16px] text-color7 cursor-pointer">
            Guardar
          </label>
        </button>
      </div>

      <CommentPostModal
        showComments={showComments}
        isOpen={showCommentArea}
        closeModal={() => setShowCommentArea(false)}
        post={post}
      />
      <LightBoxImage
        src={isOpenLightbox.src}
        isOpen={isOpenLightbox.open}
        type={isOpenLightbox.type}
        poster={isOpenLightbox.poster}
        closeModal={() =>
          setIsOpenLightbox({
            open: false,
            type: "image",
            poster: "",
            src: "",
          })
        }
      />
      {slotDescription}
    </article>
  );
}
