import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
} from "@nextui-org/react";
import { useState } from "react";

export default function VisibilityState({
  visibility_name = "",
  onVisibilityChange = () => {},
  visibilityAll = false,
}: {
  visibility_name?: string;
  onVisibilityChange?: (value: string) => void;
  visibilityAll?: boolean;
}) {
  const [visibility, setVisibility] = useState(visibility_name + "_all");
  const getNameVisibility = () => {
    switch (visibility) {
      case visibility_name + "_all":
        return "Todos";
        break;
      case visibility_name + "_friends":
        return "Mis amigos";
        break;
      case visibility_name + "_just_me":
        return "Solo yo";
        break;
    }
  };
  return (
    <div className="mt-2 flex items-center">
      <label className="w-max italic font-normal text-color1 text-[14px]">
        Este campo puede ser visto por:{" "}
        <span className="font-semibold text-color2">
          {getNameVisibility() ?? "Todos"}
        </span>
      </label>
      <div hidden={visibilityAll}>
        <Popover
          classNames={{
            content: "rounded-md",
          }}
          placement="bottom"
          showArrow
          offset={10}
        >
          <PopoverTrigger className="ml-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="min-w-5 h-5 cursor-pointer text-color2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
              />
            </svg>
          </PopoverTrigger>
          <PopoverContent className="w-[240px]">
            {(titleProps) => (
              <div className="px-1 py-2 w-full">
                <p
                  className="text-small font-bold text-foreground"
                  {...titleProps}
                >
                  Visibilidad
                </p>
                <RadioGroup
                  name={visibility_name}
                  onChange={(e) => {
                    setVisibility(e.target.value);
                    onVisibilityChange(
                      e.target.value.slice(visibility_name.length + 1)
                    );
                  }}
                  defaultValue={visibility}
                  label="¿Quién quiero que lo vea?"
                  isRequired
                  size="sm"
                  orientation="horizontal"
                  classNames={{
                    label: "font-semibold text-[14px] text-color5",
                  }}
                >
                  {[
                    {
                      label: "Todos",
                      value: visibility_name + "_all",
                    },
                    {
                      label: "Mis amigos",
                      value: visibility_name + "_friends",
                    },
                    {
                      label: "Solo yo",
                      value: visibility_name + "_just_me",
                    },
                  ].map((item) => (
                    <Radio
                      key={item.value}
                      classNames={{
                        label: "font-normal text-[14px] text-color5",
                        wrapper: "group-data-[selected=true]:border-color4",
                        control: "bg-color4",
                      }}
                      value={item.value}
                    >
                      {item.label}
                    </Radio>
                  ))}
                </RadioGroup>
              </div>
            )}
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
