import { SVGProps } from "react";

export function HeartIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.4258 1C16.3609 1 18.3332 3.79375 18.3332 6.4C18.3332 11.6781 10.148 16 9.99984 16C9.85169 16 1.6665 11.6781 1.6665 6.4C1.6665 3.79375 3.63873 1 6.57391 1C8.2591 1 9.36095 1.85312 9.99984 2.60312C10.6387 1.85312 11.7406 1 13.4258 1Z"
        stroke={props.stroke ?? "#919DAD"}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
