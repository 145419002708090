import { User } from "../../interfaces/Login";

export const age = (userData: User) => {
  if (!userData?.info?.detalles?.birthDate) return "?";
  const birthday = new Date(userData?.info?.detalles?.birthDate);
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  if (userData.info.is_couple && userData?.info?.detalles?.partner_birthDate) {
    const partnerBirthday = new Date(
      userData?.info?.detalles?.partner_birthDate
    );
    const partnerAgeDifMs = Date.now() - partnerBirthday.getTime();
    const partnerAgeDate = new Date(partnerAgeDifMs); // miliseconds from epoch
    const uAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    const pAge = Math.abs(partnerAgeDate.getUTCFullYear() - 1970);
    if (uAge == pAge) return `ambos ${pAge}`;
    return `${uAge} y ${pAge}`;
  }
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
